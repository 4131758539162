import React from 'react'
import { ReactComponent as ShoppingBag } from '../_images/shopping.svg'
import { Text } from '../components/Miscs'
import { useStyles } from '../../../../components'

const CartButton = (props) => {
  const { cartItems, setShowCartPage, isMobilePhone } = props
  const cartBtnSize = isMobilePhone ? 60 : 80
  const iconSize = Math.floor(cartBtnSize * (3 / 4))

  const { colors } = useStyles()
  const color = cartItems.length > 0 ? colors.danger : colors.primary

  const styles = {
    wrap: {
      width: cartBtnSize,
      height: cartBtnSize,
      backgroundColor: color,
      borderRadius: cartBtnSize / 2,
      position: 'fixed',
      bottom: '5vh',
      right: '5vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconWrap: {
      position: 'relative',
    },
    cntWrap: {
      position: 'absolute',
      top: isMobilePhone ? 28 : 38,
    },
  }
  return (
    <div style={styles.wrap} onClick={() => setShowCartPage(true)}>
      <div style={styles.iconWrap}>
        <ShoppingBag fill="white" width={iconSize} height={iconSize} />
      </div>
      <div style={styles.cntWrap}>
        <Text value={cartItems.length} isBold isSubtitle style={{ color }} />
      </div>
    </div>
  )
}

export default CartButton
