import React, { useEffect, useState } from 'react'
import { sendOrder } from '../../../../lib-pos/sendOrder'
import { ModalForm, Button, useStyles } from '../../../../components'
import { Divider, Price, Text } from '../components/Miscs'
import { resetCart } from '../../../../redux/cart'
import { useDispatch, useSelector } from 'react-redux'
import { saveOrderDataBeforePay } from '../../../../lib-pos/saveOrderDataBeforePay'
import { goToPayKis, notifyOrderToStore } from '../../../../lib/pay-api-lib'
import { debug } from '../../../../settings'
import CartItem from './CartItem3'

export default function Cart(props) {
  const {
    ver,
    token,
    lang,
    cartItems,
    isVisible,
    setIsVisible,
    shopInfo,
    itemOptions2,
    tableNo,
    setAlert,
  } = props

  const dispatch = useDispatch()
  const [clickDisabled, setClickDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setClickDisabled(cartItems.length === 0)
  }, [cartItems])

  const onSubmit = async () => {
    console.log('tableNo', tableNo)
    console.log('cartItems', cartItems)
    try {
      setIsLoading(true)

      if (shopInfo.payOptions.isPayable) {
        await requestPay()
      } else {
        let res = await requestOrderOnly()
        if (res.err) throw Error(res.err.message)
        setAlert('주문이 완료되었습니다.')
      }
    } catch (e) {
      setAlert(e.message)
    } finally {
      setIsLoading(false)
      setIsVisible(false)
    }
  }

  const requestOrderOnly = async () => {
    try {
      setIsLoading(true)

      let res = await sendOrder({
        shopInfo,
        cartItems, // itemList,
        itemOptions2,
        tableNo,
        token,
        ver,
      })
      if (res.err) throw Error(res.err.message)
      dispatch(resetCart())

      const orderId = res.result.saveOrder?.orderId
      console.log(shopInfo.shopId, orderId)

      if (
        shopInfo.orderNotifyOptions?.toShop ||
        shopInfo.posName === 'DUMMY_POS'
      ) {
        const resNoti = notifyOrderToStore({
          shopId: shopInfo.shopId,
          orderId,
          phone_number: '01053174673',
        })
        if (resNoti.err) throw Error(resNoti.err.message)
      }
      return {}
    } catch (e) {
      return { err: { message: e.message } }
    }
  }

  const requestPay = async () => {
    try {
      setIsLoading(true)

      let res = await saveOrderDataBeforePay({
        shopInfo,
        cartItems,
        itemOptions2,
        tableNo,
        token,
        ver,
        // notifyToShop: shopInfo.orderNotifyOptions.isPayable
      })
      if (res.err) throw Error('결제 | 주문 실패 - ', res.err.message)

      goToPayKis({ shopId: shopInfo.shopId, orderId: res.result.orderId })
    } catch (e) {
      alert(e.message)
      if (debug) console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const styles = {
    wrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '70vh',
      overflow: 'auto',
    },
  }

  return (
    <ModalForm
      visible={isVisible}
      setIsVisible={setIsVisible}
      isLoading={isLoading}
      backdrop="static"
    >
      <div style={styles.wrap}>
        <Head {...props} />
        <Divider height={6} />
        <CartInfo {...props} onSubmit={onSubmit} />
      </div>
      <Button
        variant="primary"
        title={lang.orderBtn}
        width="100%"
        onClick={onSubmit}
        disabled={clickDisabled}
      />
    </ModalForm>
  )
}

const Head = (props) => {
  const { shopInfo, lang } = props

  const styles = {
    wrap: { width: '100%', marginTop: 15 },
    head: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    text: { margin: 0, color: '#2b2b2b' },
    text2: {
      margin: 0,
      padding: '2px 3px',
      border: '1px solid #4ec6e1',
      borderRadius: '8px',
      fontSize: '14px',
      color: '#4ec6e1',
    },
  }

  return (
    <div style={styles.wrap}>
      <div style={styles.head}>
        <p style={styles.text}>{shopInfo.shopName}</p>
        <p style={styles.text2}>{lang.inStore}</p>
      </div>
    </div>
  )
}

const CartInfo = (props) => {
  const { lang, cartItems, onSubmit } = props
  const { gStyle, colors } = useStyles()

  const [totalPrice, setTotalPrice] = useState(0)

  useEffect(() => {
    let total = 0
    cartItems.map((cItem) => {
      let itemSum = parseInt(cItem.price)
      cItem.options.map((opt) => {
        itemSum += opt.optPrice
      })
      total += itemSum * cItem.count
    })
    setTotalPrice(total)
  }, [cartItems])

  const styles = {
    wrap: { flex: 1 },
    priceWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 15,
    },
    textOrder: {
      ...gStyle.subtitle,
      ...gStyle.bold,
      width: '100%',
      textAlign: 'right',
    },
  }

  return (
    <div style={styles.wrap}>
      {cartItems.map((cartItem, idx) => (
        <CartItem key={idx} {...props} cartItem={cartItem} />
      ))}

      <div style={styles.priceWrap}>
        <Price
          size="lg"
          price={totalPrice}
          lang={lang}
          style={{ color: colors.primary }}
        />
        <Text value={lang.totalPrice} style={styles.textOrder} />
      </div>

      {/* <Button
        variant="primary"
        title={lang.orderBtn}
        width="100%"
        onClick={onSubmit}
      /> */}
    </div>
  )
}
