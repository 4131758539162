import React, { useState, useEffect } from 'react'
import { Text, Price, BestNewHot, BgImage } from '../components/Miscs'
import Soldout from '../_images/soldout2.png'
import EmptyDish from '../_images/empty-dish.png'
import { getItemName } from '../../../../lib-pos'

export function MenuItemsTablet(props) {
  const { groups } = props
  const [items, setItems] = useState([])

  useEffect(() => {
    const selGroups = groups.filter((grp) => grp.selected)
    // if(debug) console.log(selGroups)
    if (selGroups.length === 1) setItems(selGroups[0].itemList)
  }, [groups])

  const styles = {
    wrap: {
      width: '100%',
      paddingTop: 15,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  }

  return (
    <div style={styles.wrap}>
      {items.length > 0 &&
        items.map((item, i) => {
          return <ItemTablet key={i} {...props} item={item} />
        })}
    </div>
  )
}

function ItemTablet(props) {
  const { item, lang, shopInfo, itemOptions } = props
  // const imgUrl = makeThumbnailPath(item.image, item.shopId)
  const imgSize = 180
  const soldoutSize = (imgSize * 2) / 3
  const borderRadius = 10

  const [itemName, setItemName] = useState(item.itemName)

  useEffect(() => {
    function start() {
      // console.log('='.repeat(50), item.itemName)
      // console.log('shopInfo', props.shopInfo)
      // console.log('itemOptions', props.itemOptions)
      // console.log('description', item.description)

      try {
        let usePosMenuFirst = itemOptions.menuSettings.usePosMenuFirst

        const _itemName = getItemName({
          shopInfo,
          item,
          usePosMenuFirst,
          selectedLanguage: 'KR',
        })

        setItemName(_itemName)
      } catch (e) {
        alert(e.message)
      }
    }
    start()
  }, [item, lang])

  const onItemClick = () => {
    if (item.isSoldout) {
      props.setAlert('Soldout 메뉴입니다.')
      return
    }
    props.setSelectedItem(item)
    props.setShowDetailPage(true)
  }

  const styles = {
    wrap: {
      margin: 5,
      // marginLeft: 15,
      border: '1px solid #aaa',
      borderRadius,
    },
    boxWrap: {
      margin: 15,
    },
    imgWrap: {
      position: 'relative',
      width: imgSize,
      height: imgSize,
    },
    image: {
      borderRadius,
      opacity: item.isSoldout ? 0.3 : 1,
      width: imgSize,
      // height: imgSize,
    },
    soldout: {
      width: imgSize,
      height: imgSize,
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    textWrap: {
      marginTop: 15,
      textAlign: 'center',
    },
    text: {
      width: imgSize,
      color: item.isSoldout ? '#dfdfdf' : 'black',
    },
  }

  /** @TODO best new hot 보여주기 */

  return (
    <div style={styles.wrap} onClick={onItemClick}>
      <div style={styles.boxWrap}>
        <div style={styles.imgWrap}>
          <img style={styles.image} src={item.image || EmptyDish} />
          {item.isSoldout && (
            <div style={styles.soldout}>
              <img src={Soldout} width={soldoutSize} alt="soldout" />
            </div>
          )}
        </div>
        <div style={styles.textWrap}>
          <Price price={item.price} lang={lang} style={styles.text} />
          <Text value={itemName} style={styles.text} />
        </div>
      </div>
    </div>
  )
}

// function getTnImageUrl(imageUrl) {
//   if (imageUrl === undefined || imageUrl === null || imageUrl === '') return ''
//   const url = new URL(imageUrl)
//   // console.log(url)
//   const arr = url.pathname.split('/')
//   arr.splice(arr.length - 1, 0, 'tn')
//   const tnUrl = url.origin + arr.join('/')
//   // console.log(tnUrl)
//   return tnUrl
// }
