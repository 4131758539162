import React from 'react'
import { useStyles } from '../../../../components'
import { Text } from '../components/Miscs'

export function GroupBarTablet(props) {
  const { groups, setGroups } = props
  const { colors } = useStyles()

  const bgColor = colors.light
  const textColor = colors.primary
  const bgColorSelected = colors.primary
  const textColorSelected = colors.light

  const handleClick2 = (item, idx) => {
    const newList = groups.map((o, i) => {
      return { ...o, selected: i === idx }
    })
    setGroups(newList)
  }

  const styles = {
    wrap: {
      width: '100%',
      // position: 'fixed',
      display: 'flex',
      // flexWrap: 'wrap',
      // top: 200,
      paddingTop: 100,
      zIndex: '99',
      overflow: 'auto',
      backgroundColor: bgColor,
    },
    groupBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 50,
      borderRight: '1px solid #eee',
      borderRadius: 3,
    },
  }

  return (
    <div style={styles.wrap}>
      {groups.map((grp, idx) => {
        return (
          <div
            key={idx}
            style={{
              ...styles.groupBox,
              backgroundColor: grp.selected ? bgColorSelected : bgColor,
            }}
            onClick={() => handleClick2(grp, idx)}
          >
            <Text
              style={{
                width: '120px',
                textAlign: 'center',
                color: grp.selected ? textColorSelected : textColor,
              }}
              isBold
              value={grp.displayName}
            />
          </div>
        )
      })}
    </div>
  )
}
