import React, { useState } from 'react'
// import { kComma } from '../../../../lib/utils'
import { kiloComma } from '../../../../lib/utils'
import Hot from "../_images/hot.png"
import New from "../_images/new.png"
import Best from "../_images/best2.png"
import { useStyles } from '../../../../components'

export function Text(props) {
  const { value, isBold, isHighLight, isTitle, isSubtitle, style } = props
  const { gStyle } = useStyles()

  let myStyle = { ...gStyle.text }
  if (isTitle) myStyle = { ...myStyle, ...gStyle.title }
  if (isSubtitle) myStyle = { ...myStyle, ...gStyle.subtitle }
  if (isBold) myStyle = { ...myStyle, ...gStyle.bold }
  if (isHighLight) myStyle = { ...myStyle, ...gStyle.highLight }
  if (style) myStyle = { ...myStyle, ...style }

  return <p style={myStyle}>{value}</p>
}

export function Price({ price, lang, size, width, style }) {
  const { gStyle } = useStyles()

  const textStyle = size === "lg" ? gStyle.subtitle : gStyle.text

  // const value = lang.langName === '한'
  //   ? `${kiloComma(price)}${lang.won}`
  //   : `${lang.won}${kiloComma(price)}`
  const value = `${kiloComma(price)}원`

  const _style = {
    ...textStyle,
    ...gStyle.bold,
    width: width || "100%"
  }

  const myStyle = { ..._style, ...style }

  return <p style={myStyle}>{value}</p>
}

export function BestNewHot({ item }) {
  const isSoldout = item.isSoldout
  const styles = {
    item: { marginRight: 5, width: '30px' },
  }

  return (
    <div>
      {!isSoldout && (
        <>
          {item.isBest && <img src={Best} style={styles.item} alt="best" />}
          {item.isNew && <img src={New} style={styles.item} alt="new" />}
          {item.isHot && <img src={Hot} style={styles.item} alt="hot" />}
        </>
      )}
    </div>
  )
}

export function ChangeQty({ itemCount, setItemCount, size }) {
  const height = size === 'sm' ? 25 : 35
  const width = 35

  const onDecerease = () => {
    if (itemCount === 1) return
    setItemCount((prev) => prev - 1)
  }

  const onIncrease = () => {
    setItemCount((prev) => prev + 1)
  }

  const styles = {
    wrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
      borderRadius: '8px',
    },
    text: { textAlign: 'center', width, margin: 0 },
  }

  return (
    <div style={styles.wrap}>
      <OutlineButton
        title="-"
        color="white"
        width={width}
        height={height}
        onClick={onDecerease}
        disabled={itemCount < 2}
      />
      {/* <div style={{ padding: '0px 15px' }}>{itemCount}</div> */}
      <p style={styles.text}>{itemCount}</p>
      <OutlineButton
        title="+"
        color="white"
        width={width}
        height={height}
        onClick={onIncrease}
      />
    </div>
  )
}

export function Divider({ height, color, margin }) {
  return (
    <div
      style={{
        width: '100%',
        height: height || 2,
        backgroundColor: color || '#eee',
        marginTop: margin || 15,
        marginBottom: margin || 15,
      }}
    />
  )
}

export function BgImage(props) {
  const { image, width, height, children, style } = props;
  return (
    <div
      style={{
        padding: style?.padding,
        margin: style?.margin,
        border: style?.border,
        borderColor: style?.borderColor,
        borderRadius: style?.borderRadius,
        opacity: style?.opacity,
        backgroundImage: `url(${image.split(' ').join('%20')})`,   // 이미지 url에 띄어쓰기가 있을경우 해결.
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: width || '100%',
        height: height,
        top: 0,
      }}
    >{children}</div>
  )
}

function OutlineButton(props) {
  const { children, color, opacity = 1, style, title, width = '100%', height = 44 } = props;
  const { fontSize = 16, borderRadius = 5, disabled = false } = props;
  const { onClick, value } = props;
  const [textColor, setTextColor] = useState(
    color === "green" ? "#05b8a0" :
      color === "blue" ? "#4ec6e1" :
        color === 'gray' ? '#b2b2b2' :
          color === 'white' ? 'black' : "white");
  const styles = {
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: width,
      height: height,
      border: "1px solid",
      outline: 0,
      borderColor: textColor,
      borderRadius: borderRadius,
      backgroundColor: 'white',
      opacity: opacity,
      color: textColor,
      fontSize: fontSize,
      fontWeight: "bold",
      whiteSpace: 'pre-line'
    },
  };
  const onChangeEvent = () => {
    setTextColor(color === 'gray' ? '#e8e8e8' :
      color === 'blue' ? '#77d1e8' : '#07c8ae');
  }
  const onMouseOut = () => {
    setTextColor(color === 'gray' ? '#b2b2b2' :
      color === 'blue' ? '#4ec6e5' : '#05b8a0');
  }
  return (
    <button
      style={{ ...styles.buttonContainer, ...style, color: textColor }}
      value={value}
      disabled={disabled}
      onClick={onClick}
      onMouseOver={onChangeEvent}
      onMouseOut={onMouseOut}
      onMouseDown={onChangeEvent}
      onMouseUp={onMouseOut}
    >
      {children}
      {title}
    </button>
  );
}