import React, { useEffect, useState } from "react";
import { ModalForm, useStyles } from "../../../components";
import { CustomTable2 } from "../../../components/Tables2";
import { updateItemOption } from "../../../lib-pos";
import { ChevronD, ChevronU } from "../../../components/Icons";
import { optionTypes } from "./OkPosOptionMenus";
import { debug } from "../../../settings";

const headers = {
  // SDA
  SdaClsList: [
    { name: "selected", displayName: "선택", type: "checkbox", keyName: "SDA_CLS_CD" },
    { name: "SDA_CLS_CD", displayName: "코드(SDA_CLS_CD)", selectKey: true },
    { name: "SDA_CLS_NM", displayName: "이름" },
    // { name: "USE_YN", displayName: "사용여부" },
    { name: "required", displayName: "필수여부", type: "boolean" },
  ],
  SdaCdList: [
    { name: "selected", displayName: "선택", type: "checkbox", keyName: "SDA_CD" },
    { name: "SDA_CD", displayName: "코드(SDA_CD)", selectKey: true },
    { name: "SDA_CLS_CD", displayName: "분류코드(SDA_CLS_CD)" },
    { name: "SDA_NM", displayName: "이름" },
    { name: "isSoldout", displayName: "품절" },
  ],

  // SDS
  SdsClsList: [
    { name: "selected", displayName: "선택", type: "checkbox", keyName: "SDS_CLS_CD" },
    { name: "SDS_CLS_CD", displayName: "코드(SDS_CLS_CD)", selectKey: true },
    { name: "SDS_CLS_NM", displayName: "이름" },
    { name: "required", displayName: "필수여부", type: "boolean" },
  ],
  SdsCdList: [
    { name: "selected", displayName: "선택", type: "checkbox", keyName: "SDS_CD" },
    { name: "SDS_CD", displayName: "코드(SDS_CD)", selectKey: true },
    { name: "SDS_CLS_CD", displayName: "분류코드(SDS_CLS_CD)" },
    { name: "SDS_NM", displayName: "이름" },
    { name: "isSoldout", displayName: "품절" },
  ],
}

export function OKPosOptionsModal(props) {
  const {
    optionType, // SdsCls | SdsCd | SdaCls | SdaCd
    sdaClsList, sdaCdList,
    sdsClsList, sdsCdList,
    visible,
    setVisible,
    shopInfo,
    onload,
    isLoading, setIsLoading
  } = props;

  let modalTitle = "잘못된 접근입니다."

  if (optionType === optionTypes.sdaCls) modalTitle = "속성분류 순서/필수여부 세팅"
  if (optionType === optionTypes.sdaCd) modalTitle = "속성항목 순서/품절여부 세팅"
  if (optionType === optionTypes.sdsCls) modalTitle = "선택분류 순서/필수여부 세팅"
  if (optionType === optionTypes.sdsCd) modalTitle = "선택항목 순서/품절여부 세팅"

  const { gStyle } = useStyles()
  const [tHeader, setTHeader] = useState([])
  const [optItems, setOptItems] = useState([])

  useEffect(() => {
    function start() {

      let optList = []

      if (optionType === optionTypes.sdaCls) {
        optList = sdaClsList.map(o => {
          return { ...o, required: o.required || false }
        })
        setTHeader(headers.SdaClsList)
      }

      if (optionType === optionTypes.sdaCd) {
        optList = sdaCdList.map(o => {
          return { ...o, isSoldout: o.isSoldout ? o.isSoldout : false }
        })
        setTHeader(headers.SdaCdList)
      }

      if (optionType === optionTypes.sdsCls) {
        if (debug) console.log('optionType', optionType)
        optList = sdsClsList.map(o => {
          return { ...o, required: o.required || false }
        })
        setTHeader(headers.SdsClsList)
      }

      if (optionType === optionTypes.sdsCd) {
        optList = sdsCdList.map(o => {
          return { ...o, isSoldout: o.isSoldout ? o.isSoldout : false }
        })
        setTHeader(headers.SdsCdList)
      }

      setOptItems(optList)
    }
    start()
  }, [
    optionType,
    sdaClsList,
    sdaCdList,
    sdsClsList,
    sdsCdList
  ])

  const onSubmit = async () => {
    try {
      setIsLoading(true)

      const newList = optItems.map((obj, i) => {
        const obj2 = { ...obj, sortOrder: i }
        delete obj2.selected
        return obj2
      })

      if(debug) {
        console.log('='.repeat(50), optionType)
        console.table(newList)
      }
      // throw Error('test...')

      let body = {}

      // SDA
      if (optionType === optionTypes.sdaCls) body = { SdaClsList: JSON.stringify(newList) }
      if (optionType === optionTypes.sdaCd) body = { SdaCdList: JSON.stringify(newList) }

      // SDS
      if (optionType === optionTypes.sdsCls) body = { SdsClsList: JSON.stringify(newList) }
      if (optionType === optionTypes.sdsCd) body = { SdsCdList: JSON.stringify(newList) }

      let res = await updateItemOption({ shopInfo, body })
      if (res.err) throw Error(res.err.message)
      await onload()
    } catch (e) {
      if (debug) console.log(e)
      alert(e.message);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  }

  const onChangeChecked = (obj) => {
    // const items = [...optItems]

    const newItems = optItems.map(item => {

      if (optionType === optionTypes.sdaCls) {
        if (item["SDA_CLS_CD"] === obj["SDA_CLS_CD"]) {
          return { ...item, required: !obj.required }
        } else return item
      }

      if (optionType === optionTypes.sdaCd) {
        if (item["SDA_CD"] === obj["SDA_CD"]) {
          return { ...item, isSoldout: !obj.isSoldout }
        } else return item
      }

      if (optionType === optionTypes.sdsCls) {
        if (item["SDS_CLS_CD"] === obj["SDS_CLS_CD"]) {
          return { ...item, required: !obj.required }
        } else return item
      }

      if (optionType === optionTypes.sdsCd) {
        if (item["SDS_CD"] === obj["SDS_CD"]) {
          return { ...item, isSoldout: !obj.isSoldout }
        } else return item
      }

    })

    setOptItems(newItems)
  }

  const onMove = (direction) => {
    // const newItems = [...sdsClasses]
    const newItems = [...optItems]

    let idx = newItems.findIndex(item => item.selected)
    if (idx < 0) return null
    const obj = newItems[idx]

    newItems.splice(idx, 1)
    if (direction === "up") {
      idx = idx === 0 ? idx : --idx
    } else {
      idx = idx === newItems.length ? idx : ++idx
    }
    newItems.splice(idx, 0, obj)
    // setSdsClasses(newItems)
    setOptItems(newItems)
  }

  const styles = {
    formGroup: { margin: "15px 5px" }
  }

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setVisible}
      title={modalTitle}
      isLoading={isLoading}
      onSubmit={onSubmit}
      size={"lg"}
      backdrop="static"
    >
      <div style={styles.formGroup}>
        {/* <p style={gStyle.text}>아이템을 클릭하면 필수여부가 변경됩니다.</p> */}
        <div style={{
          display: "flex",
          justifyContent: "space-between", alignItems: "center",
          margin: 5
        }}>
          <p style={{ ...gStyle.text }}>편집 후 확인을 누르세요.</p>
          <div style={{
            border: "1px solid #ddd",
            borderRadius: 5,
            padding: "5px 10px",
            display: "flex",
            justifyContent: "space-between", alignItems: "center"
          }}>
            <p style={{ ...gStyle.text, marginRight: 5 }}><ChevronU onClick={() => onMove("up")} /></p>
            <p style={{ ...gStyle.text }}><ChevronD onClick={() => onMove("down")} /></p>
          </div>
        </div>

        {/* <CustomTable tableHeader={tHeader} rowItems={sdsClasses} onClickItem={onChangeChecked} /> */}
        <CustomTable2
          tableHeader={tHeader}
          rowItems={optItems}
          setRowItems={setOptItems}
          // rowItems={sdsClasses}
          // setRowItems={setSdsClasses}
          onClickItem={onChangeChecked}
        />
      </div>
    </ModalForm>
  )
}
