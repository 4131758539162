import React from 'react'
import { useSelector } from 'react-redux'
import { useStyles } from '../../../components'

export function DataProvider({ Component, ...rest }) {
  const { shopInfo, selectedLanguage } = useSelector((state) => state.shop)
  const { itemList3, itemOptions3 } = useSelector((state) => state.items)
  const { cartItems } = useSelector((state) => state.cart)
  const { isMobileDevice, isTablet, isMobilePhone } = useSelector(
    (state) => state.platform,
  )
  const { gStyle, colors } = useStyles()

  // const lang = setLanguage(selectedLanguage)
  // const texts = lang

  const allProps = {
    // ...props,
    ver: 'v3',
    shopInfo,
    itemList: itemList3,
    itemOptions: itemOptions3,
    itemOptions2: itemOptions3,
    cartItems,
    isMobileDevice,
    isTablet,
    isMobilePhone,
    // lang, texts,
    gStyle,
    colors,
  }

  // const udpateChildrenWithProps = React.Children.map(
  //   children,
  //   (child, i) => {
  //     return React.cloneElement(child, {
  //       ...rest,
  //       ...allProps,
  //       // onClick: onClick
  //     })
  //   }
  // )
  // return <>{udpateChildrenWithProps}</>
  return <Component {...rest} {...allProps}/>
}

// export function DataProvider(props) { return <_DataProvider {...props} ><PencilIcon /></_DataProvider> }

// export default function DataProvider({children}) {
  

//   const styles = {
//     wrapOuter: {
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       width: '100%',
//       height: '100vh',
//       backgroundColor: '#fff',
//     },
//     wrapInner: {
//       marginLeft: 'auto',
//       marginRight: 'auto',
//       width: '80%',
//       height: '60vh',
//       // border: '1px solid #555',
//       borderRadius: 10,
//       display: 'flex',
//       justifyContent: 'space-between',
//     },
//   }

//   return (
//     <div style={styles.wrapOuter}>
//       <div style={styles.wrapInner}>
//         <StoreInfoBox {...allProps} />
//         <BtnBox {...allProps} />
//       </div>
//     </div>
//   )
// }