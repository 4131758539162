import { sortObjArray2 } from "../../../../lib/utils"
import { debug } from "../../../../settings"

const pos = {
  "UNIONPOS": { createValidMenuList: createValidMenuListUnionpos, },
  "OKPOS": { createValidMenuList: createValidMenuListOkpos, },
  "DUMMY_POS": { createValidMenuList: createValidMenuListDpos, }
}

/** @TODO itemName, itemName2, lanquage 처리할 것 */

export function createValidMenuList({ shopInfo, itemList, itemOptions2 }) {
  return pos[shopInfo.posName].createValidMenuList({ itemList, itemOptions2 })
}

function createValidMenuListUnionpos({ itemList, itemOptions2 }) {
  const menuList = []
  itemOptions2.GrpList.map((g, i) => {
    if (g.name === undefined) return null
    if (g.name?.trim() === '') return null
    if (g.hidden) return null

    const items = itemList.filter(o => o.groupCode === g.GrpCode)
    menuList.push({ ...g, selected: i === 0, itemList: items })
    return null
  })

  return { menuList }
}

function createValidMenuListOkpos({ itemList, itemOptions2 }) {
  const tuClsList = sortObjArray2({ arr: itemOptions2.TuClsList, key: "sortOrder" })
  // if(debug) console.table(tuClsList)

  const menuList = []
  tuClsList.map((g, i) => {
    if (g.name === undefined) return null
    if (g.name?.trim() === '') return null
    if (g.hidden) return null
    if (g.itemList.length === 0) return null

    const items = []
    g.itemList.map(itemCode => {
      const items2 = itemList.filter(o => o.itemCode === itemCode)
      if (items2.length === 1) items.push(items2[0])
      return null
    })

    menuList.push({...g, selected: i===0, itemList: items, itemCodeList: g.itemList})
  })

  return { menuList }
}

function createValidMenuListDpos({ itemList, itemOptions2 }) {
  const groupList = sortObjArray2({ arr: itemOptions2.groupList, key: "sortOrder" })

  const menuList = []
  groupList.map((g, i) => {
    if (g.groupName === undefined) return null
    if (g.groupName?.trim() === '') return null
    if (g.hidden) return null

    const items = itemList.filter(o => o.groupCode === g.groupCode)

    menuList.push({...g, selected: i===0, itemList: items})
  })

  return { menuList }
}