import React, { useState, useEffect } from "react";
import { getItemList, parseItemOptions, getItemOptions } from "../../../lib-pos";
import { OnlDropdown, useStyles } from "../../../components";
import {
  Save, Upload,
  // EyeOpen,
  // EyeClose,
  Setting,
  Recycle,
  Grouping,
  Sorting,
  LanguageIcon,
  TextFile,
  Plus
} from "../../../components/Icons";
import { debug } from "../../../settings";
import { Texts } from "../Texts";
import GroupBar from "../components/GroupBar";
import MenuList from "../components/MenuList";
import MenuUpdateModal from "../components/MenuUpdateModal";
import MenuResetModal from "../components/MenuResetModal";
import MenuSettingModal from "../components/MenuSettingModal";
import MenuUploadExcelModal from "../components/MenuUploadExcelModal";
import MenuSave2ExcelModal from "../components/MenuSaveToExcelModal";
import SettingTableCallModal from "../components/SettingTableCallModal";
// import SoldoutModal from "../components/SoldoutModal";
import { SoldoutModal2 } from "../components/SoldoutModal2";
import { OnlMenuModal } from "../components/ONLmenuModal";
// import SortingModal from "../components/SortingModal";
import SortingModal from "../components/SortingModal2";
import ChangeLangModal from "../components/ChangeLangModal";
import { useSelector, useDispatch } from "react-redux";
import { setItemList3, setItemOptions3 } from "../../../redux/items";
import { getShortArray, sortObjArray2 } from "../../../lib/utils";

const str = {
  noItemFound: "NO ITEM FOUND",
  failedItemOptions: "옵션정보가 없습니다.",
  failedPosname: "지원하지 않는 포스입니다.",
  alertFail: "정보를 가져오는 데 실패했습니다!",
  alertFailUpdate: "정보를 수정하는 데 실패했습니다!",
  alertNoMenu: "메뉴불러오기를 실행하였는지 확인하세요!",
  excelUpload: "메뉴 파일(엑셀) 업로드",
  excelDownload: "메뉴 파일(엑셀) 저장",
}

export function StoreMenuInfo(props) {
  const { shopInfo, selectedLanguage, posName } = useSelector(state => state.shop);
  const { itemList3, itemOptions3 } = useSelector(state => state.items)
  const dispatch = useDispatch()
  const shopId = shopInfo.shopId;

  // if (debug) console.log(posName, selectedLanguage)

  const [isloaded, setIsloaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [itemList, setItemList] = useState([]);
  const [itemOptions, setItemOptions] = useState({});
  const [itemOptions2, setItemOptions2] = useState({});
  const [selectedGroup2, setSelectedGroup2] = useState({});
  const [isMenuClicked, setMenuClicked] = useState(false);
  const [showRemoved, setShowRemoved] = useState(false);
  const [usePosMenuFirst, setPosMenuFirst] = useState(false);

  const [visible, setVisible] = useState(false);
  const [visibleUploadExcel, setVisibleUploadExcel] = useState(false);
  const [visibleSave2Excel, setVisibleSave2Excel] = useState(false);
  const [visibleSetting, setVisibleSetting] = useState(false);
  const [visibleTableCall, setVisibleTableCall] = useState(false);
  const [visibleMenuReset, setVisibleMenuReset] = useState(false)
  const [visibleSoldout, setVisibleSoldout] = useState(false)
  const [visibleGrpSorting, setVisibleGrpSorting] = useState(false)
  const [visibleChangeLang, setVisibleChangeLang] = useState(false)

  // 
  // OnlMenu Add | Edit (item, group)
  //   
  const [visibleOnlMenu, setVisibleOnlMenu] = useState(false)

  useEffect(() => {
    onload();
  }, []);

  const _setMenuSettings = (_itemOptions) => {
    // const menuSettings = isJson(_itemOptions.menuSettings) ? JSON.parse(_itemOptions.menuSettings) : {};
    // if (debug) console.log('menuSettings', menuSettings);
    const menuSettings = _itemOptions.menuSettings || {};
    setPosMenuFirst(menuSettings.usePosMenuFirst === true);
  }

  // 
  // shopItemList, shopItemOpstionGet
  // 
  const onload = async () => {
    try {
      setIsLoading(true);

      // 
      // get itemList
      // 
      let res = {}
      res = await getItemList({ shopInfo });
      if (res.err) throw new Error(str.alertFail + "\n" + res.err.message);

      const sItemList = sortObjArray2({ arr: res.result.Items, key: "sortOrder" })

      setItemList(sItemList);
      dispatch(setItemList3({ itemList3: sItemList }))

      res = await getItemOptions({ shopInfo })

      if (res.err) {
        if (res.err.message !== str.noItemFound) throw new Error(str.alertFail + "\n" + res.err.message);
        throw new Error("메뉴정보가 없습니다.\n '메뉴정보'의 'POS메뉴 불러오기'를 실행하세요!");
      }
      const options = parseItemOptions({ shopInfo, itemOptions: res.result.Item, selectedLanguage });

      setItemOptions2(options);
      setItemOptions(res.result.Item);
      dispatch(setItemOptions3({ itemOptions3: options }))

      _setMenuSettings(options); // 메뉴세팅 함수 호출
      setIsloaded(true);

      if (debug) {
        // logItemList({ shopInfo, itemList: sItemList })
        logItemOptions({ itemOptions: options })
        // if(shopInfo.posName==="EASY_POS") logEasyposMenu({itemList: sItemList, itemOptions: options})
      }
    } catch (e) {
      alert(e.message);
    } finally {
      setIsloaded(true)
      setIsLoading(false);
    }
  }

  const menuProps = {
    shopInfo, shopId, posName,
    selectedGroup2, setSelectedGroup2,
    itemOptions,
    itemOptions2, // 모든 속성을 JSON.parse 함
    itemOptions3, // redux
    usePosMenuFirst,
    itemList,
    itemList3, // redux
    setMenuClicked, isMenuClicked,
    isLoading, setIsLoading,
    showRemoved, setShowRemoved,
    onload,
    setVisibleUploadExcel, setVisibleSave2Excel
  }

  const otherProps = {
    setVisible,
    setVisibleMenuReset,
    setVisibleSetting,
    setVisibleTableCall,
    setVisibleSoldout,
    setShowRemoved, showRemoved,
    goToEditMenus: () => props.history.push({
      pathname: "/store/option-menus",
      data: { ...menuProps }
    }),
    setVisibleGrpSorting,
    setVisibleChangeLang,
    setVisibleOnlMenu,
  }

  return (
    <>
      <MenuHeader {...props} {...menuProps} {...otherProps} mode={isMenuClicked ? "menuList" : "groupBar"} />

      {isMenuClicked
        ? <MenuList {...menuProps} />
        : <GroupBar {...menuProps} />}

      <MenuUpdateModal
        {...props}
        {...menuProps}
        visible={visible}
        setVisible={setVisible}
      />

      {isloaded &&
        <>
          <MenuResetModal
            {...props}
            {...menuProps}
            visible={visibleMenuReset}
            setVisible={setVisibleMenuReset}
          />

          <MenuSettingModal
            {...props}
            {...menuProps}
            visible={visibleSetting}
            setVisible={setVisibleSetting}
          />

          <SettingTableCallModal
            {...props}
            {...menuProps}
            visible={visibleTableCall}
            setVisible={setVisibleTableCall}
          />

          {/* <SoldoutModal
            {...props}
            {...menuProps}
            visible={visibleSoldout}
            setVisible={setVisibleSoldout}
          /> */}

          <SoldoutModal2
            mode="clear"
            {...props}
            {...menuProps}
            visible={visibleSoldout}
            setVisible={setVisibleSoldout}
          />

          <SortingModal
            {...props}
            {...menuProps}
            type="group"
            visible={visibleGrpSorting}
            setVisible={setVisibleGrpSorting}
          />

          <ChangeLangModal
            {...props}
            {...menuProps}
            visible={visibleChangeLang}
            setVisible={setVisibleChangeLang}
          />

          {/** @DUMMY_POS */}
          <MenuUploadExcelModal
            {...props}
            {...menuProps}
            visible={visibleUploadExcel}
            setVisible={setVisibleUploadExcel}
          />
          <MenuSave2ExcelModal
            {...props}
            {...menuProps}
            visible={visibleSave2Excel}
            setVisible={setVisibleSave2Excel}
          />
          <OnlMenuModal
            {...props}
            {...menuProps}
            {...otherProps}
            isEdit={false}
            editItem={{}}
            type="group"
            visible={visibleOnlMenu}
            setVisible={setVisibleOnlMenu}
          />
        </>
      }

    </>
  )
}

function MenuHeader(props) {
  const {
    shopInfo, posName,
    setVisible,
    setVisibleMenuReset,
    setVisibleSetting,
    setVisibleTableCall,
    setVisibleSoldout,
    // setShowRemoved,
    // showRemoved,
    mode, setVisibleGrpSorting,
    setVisibleChangeLang,
    setVisibleUploadExcel, setVisibleSave2Excel
  } = props;

  const { isMobileDevice } = useSelector((state) => state.platform);
  const { selectedLanguage } = useSelector(state => state.shop)
  const { gStyle, colors } = useStyles()

  const styles = {
    header: {
      padding: "0px 15px",
      marginBottom: "15px",
      display: 'flex',
      alignItems: "center",
      justifyContent: (mode === "groupBar") ? 'space-between' : 'flex-end',
    }
  }

  return (
    <div style={styles.header}>

      {/* Group Sorting Modal Visible */}

      {(mode === "groupBar") &&
        <div style={{ display: "flex", alignItems: "center" }}>
          <Sorting
            style={{ ...gStyle.subtitle, color: colors.primary, marginRight: 5 }}
            onClick={() => setVisibleGrpSorting(true)}
          />
          {/* <p style={gStyle.text}>&nbsp;그룹순서</p> */}

          {shopInfo.posName === "DUMMY_POS" &&
            <Plus
              style={{ ...gStyle.subtitle, color: colors.primary }}
              onClick={() => props.setVisibleOnlMenu(true)}
            />
          }
        </div>
      }

      <OnlDropdown
        title="메뉴 설정"
        id="menu-setting"
        width={isMobileDevice ? "220px" : "230px"}
        size="lg"
      >

        {/* 메뉴불러오기 */}
        <OnlDropdown.Item onClick={() => {
          if (posName === "DUMMY_POS") setVisibleUploadExcel(true)
          else setVisible(true)
        }}>
          {posName === "DUMMY_POS" ? <Upload /> : <Save />}
          &nbsp;&nbsp;
          {posName === "DUMMY_POS" ? str.excelUpload : Texts.shopMenu.posBtnTitle}
        </OnlDropdown.Item>

        {debug &&
          <OnlDropdown.Item onClick={() => setVisibleSave2Excel(true)}>
            <Save />&nbsp;&nbsp;{str.excelDownload}
          </OnlDropdown.Item>}

        {/* 옵션 메뉴 */}
        <OnlDropdown.Item onClick={() => props.goToEditMenus()}>
          <Grouping />&nbsp;&nbsp;옵션(메뉴,테이블) 편집
        </OnlDropdown.Item>

        {/* {shopInfo.posName === "OKPOS" &&
          <OnlDropdown.Item onClick={() => props.goToEditMenus()}>
            <Grouping />&nbsp;&nbsp;옵션(메뉴,테이블) 편집
          </OnlDropdown.Item>
        } */}


        {/* 메뉴 리셋 */}
        {debug &&
          <OnlDropdown.Item onClick={() => setVisibleMenuReset(true)}>
            <Recycle />&nbsp;&nbsp;{Texts.shopMenu.posBtnReset}
          </OnlDropdown.Item>}

        <OnlDropdown.Divider />

        {/* 메뉴명 옵션 */}
        <OnlDropdown.Item onClick={() => setVisibleSetting(true)}>
          <TextFile />&nbsp;&nbsp;메뉴명 세팅
        </OnlDropdown.Item>

        <OnlDropdown.Item onClick={() => setVisibleChangeLang(true)}>
          <LanguageIcon />
          &nbsp;메뉴언어 선택 ({selectedLanguage})
        </OnlDropdown.Item>

        <OnlDropdown.Divider />

        {/* 테이블 콜 */}
        <OnlDropdown.Item onClick={() => setVisibleTableCall(true)}>
          <Setting />&nbsp;&nbsp;테이블콜 세팅
        </OnlDropdown.Item>

        <OnlDropdown.Item onClick={() => setVisibleSoldout(true)}>
          <Recycle />&nbsp;&nbsp;품절상품 관리
        </OnlDropdown.Item>

        {/* <OnlDropdown.Divider /> */}

        {/* <OnlDropdown.Item onClick={() => setShowRemoved(!showRemoved)}>
          {showRemoved ? <EyeOpen /> : <EyeClose />}
          &nbsp;삭제된 메뉴 {showRemoved ? "숨기기" : "보기"}
        </OnlDropdown.Item> */}

      </OnlDropdown>
    </div>
  )
}

export function logItemList({ shopInfo, itemList }) {
  const posName = shopInfo.posName

  console.log('itemList original', JSON.stringify(itemList).length, " bytes")

  const itemNames = {
    "OKPOS": ["groupCode", "itemCode", "itemName", "price", "SIDE_MENU_YN", "tuClassList"],
    "UNIONPOS": ["groupName", "groupCode", "itemCode", "itemName", "menuType2", "subCol1"],
    "OASIS_POS": ["groupCode", "itemCode", "itemName"],
    "EASY_POS": ["groupCode", "itemCode", "itemName", "KIOSK_SUB_MENU_TYPE", "description"],
    "DUMMY_POS": ["groupCode", "itemCode", "itemName", "itemName2", "displayName", "price", "optionGroupCode"],
  }

  let newItemList = getShortArray(itemList, itemNames[posName])
  console.log("=".repeat(50), "itemList", newItemList.length, "개 항목")
  console.log("=".repeat(50), "itemList", Math.floor(JSON.stringify(itemList).length / 1000), " kbytes")
  console.table(newItemList)
}

export function logItemOptions({ itemOptions }) {
  console.log("=".repeat(50), "itemOptions")
  console.log(itemOptions)
  // console.log(Object.keys(itemOptions))

  if (itemOptions.optionName === "unionpos") {
    console.log("GrpList")
    console.table(itemOptions.GrpList)
  }

  if (itemOptions.optionName === "OKPOS") {
    console.log("=".repeat(50), "TuClsList", itemOptions.TuClsList.length, "개")
    console.table(itemOptions.TuClsList)

    console.log("=".repeat(50), "SdaClsList", itemOptions.SdaClsList.length, "개")
    console.table(itemOptions.SdaClsList)
    console.log("-".repeat(50), "SdaCdList", itemOptions.SdaCdList.length, "개")
    console.table(itemOptions.SdaCdList)

    console.log("=".repeat(50), "SdsGrList", itemOptions.SdsGrList.length, "개")
    console.table(itemOptions.SdsClsList)
    console.log("-".repeat(50), "SdsClsList", itemOptions.SdsClsList.length, "개")
    console.table(itemOptions.SdsClsList)
    console.log("-".repeat(50), "SdsCdList", itemOptions.SdsCdList.length, "개")
    console.table(itemOptions.SdsCdList)
  }

  if (itemOptions.optionName === "DUMMY_POS") {
    console.log("-".repeat(30), "groupList")
    console.table(itemOptions.groupList)

    console.log("-".repeat(30), "tableList")
    console.table(itemOptions.tableGroupList)
    console.table(itemOptions.tableList)

  }

  if (itemOptions.optionName === "OASIS_POS") {
    console.table(itemOptions.groupList)
  }

  if (itemOptions.optionName === "EASY_POS") {
    console.log("-".repeat(30), "groupList")
    console.table(itemOptions.touchClassList)
    // console.table(itemOptions.orderList)
    // console.table([itemOptions.subItemList[0]])

    // const names = [
    //   'touchClassList',
    //   'subItemList',
    //   'orderClassList', 'orderList', 'itemOrderClassList',
    //   'tableGroupList', 'tableList',
    // ]
    // names.map(n => {
    //   console.log('-'.repeat(30), n)
    //   console.log(JSON.stringify(itemOptions[n][0], null, 2))
    //   return null
    // })
  }
}

function logEasyposMenu({ itemList, itemOptions }) {
  console.log("-".repeat(30), "groupList")
  console.table(itemOptions.groupList)

  const sItemList = sortObjArray2({ arr: itemList, key: "groupCode" })
  const items = getShortArray(sItemList, [
    "groupCode", "itemCode", "itemName", "KIOSK_SUB_MENU_TYPE", "description"
  ])
  console.log("-".repeat(30), "itemList", items.length)
  // console.table(items)
  // console.table(sItemList)
}
