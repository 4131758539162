import React from 'react'
import { DataProvider } from '../../components-pos'

export default function SalesIndex(props) {
  return <DataProvider {...props} Component={Index__} />
}

function Index__(props) {
  
  const styles = {
    wrapOuter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100vh',
      backgroundColor: '#eef',
    },
    wrapInner: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '80%',
      height: '60vh',
      // border: '1px solid #555',
      borderRadius: 10,
      display: 'flex',
      justifyContent: 'space-between',
    },
  }

  return (
    <div style={styles.wrapOuter}>
      <div style={styles.wrapInner}>
        <h1>Sale Home</h1>
      </div>
    </div>
  )
}
