import React from 'react'
import { useSelector } from 'react-redux'
import MenuHome from './MenuHome'
// import { getShortArray } from '../../../lib/utils'
import { setLanguage } from './settings/langs'

// const str = {
//   noItemFound: 'NO ITEM FOUND',
//   failedShopGet: '가게 정보를 가져오는데 실패했습니다.',
//   seeDetail: '자세히 보기',
//   menuSectionTitle: '메뉴 정보',
// }

export default function TestOrder(props) {
  const { shopInfo, selectedLanguage } = useSelector((state) => state.shop)
  const { itemList3, itemOptions3 } = useSelector((state) => state.items)
  const { cartItems } = useSelector((state) => state.cart)
  const { isMobileDevice, isTablet, isMobilePhone } = useSelector(
    (state) => state.platform,
  )

  const lang = setLanguage(selectedLanguage)
  const texts = lang

  // const cartItems2 = getShortArray(cartItems, ["itemName", "itemCode", "count", "price", "options", "payAmount"])
  // console.table(cartItems2)
  // console.log('cartItems', JSON.stringify(cartItems, null, 2 ))
  // console.log(isMobileDevice)
  // console.log(shopInfo)
  // console.log(itemList3)
  // console.log(itemOptions3)

  const styles = {
    wrapOuter: { width: '100%' },
    wrapInner: {
      height: "100vh",
      overflow: 'auto',
      // marginLeft: 'auto',
      // marginRight: 'auto',
      // width: isMobileDevice ? '100%' : '500px',
      width: "100%",
      backgroundColor: '#fff',
    },
  }

  const allProps = {
    ...props,
    ver: 'v3',
    shopInfo,
    tableNo: "007",
    itemList: itemList3,
    itemOptions: itemOptions3,
    itemOptions2: itemOptions3,
    cartItems,
    isMobileDevice, isTablet, isMobilePhone,
    lang, texts,
  }

  return (
    <div style={styles.wrapOuter}>
      <div style={styles.wrapInner}>
        <MenuHome {...allProps} />
      </div>
    </div>
  )
}
