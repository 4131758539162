import { getOnlOptionItems, getOptionItemsDummypos } from "./getOnlOptionItems";
import { debug } from "../settings";


const pos = {
  "UNIONPOS": { getSetmenuText: getSetmenuTextUnionpos },
  "OKPOS": { getSetmenuText: getSetmenuTextOkpos },
  "DUMMY_POS": { getSetmenuText: getSetmenuTextDummypos },
  "OASIS_POS": { getSetmenuText: getSetmenuTextOasispos },
  "EASY_POS": { getSetmenuText: getSetmenuTextEasypos },
}

const posList = Object.keys(pos)

/**
 * 
 * @param {*} params { shopInfo, item, itemList, itemOptions2, usePosMenuFirst }
 * @returns 
 */
export function getSetmenuText(params) {
  if (!posList.includes(params.shopInfo.posName)) return "";
  return pos[params.shopInfo.posName].getSetmenuText(params);
}


const maxLen = 50


function getSetmenuTextUnionpos({ item, itemList, itemOptions2, usePosMenuFirst }) {
  try {
    const setMenuTypes = ["FIXED", "DYNAMIC"];
    const choiceMenuTypes = ["CHOICE"];

    if (item.menuCode === undefined) return "";
    if (item.menuCode === "") return "";

    // 
    // 세트메뉴 혹은 초이스메뉴 선택
    //
    let setMenuList;

    // SetMenu 아이템
    if (setMenuTypes.includes(item.menuType2)) {
      setMenuList = itemOptions2.SetMenuList || [];
    }

    // ChoiceMenu 아이템
    else if (choiceMenuTypes.includes(item.menuType2)) {
      setMenuList = itemOptions2.ChoiceMenuList || [];
    }

    else return "";

    let mySetMenuList = setMenuList.filter(el => el.MenuCode === item.menuCode);

    let setItemNames = mySetMenuList.map(setmenu => {
      let item = itemList.filter(el => setmenu.ItemCode === el.itemCode);
      return usePosMenuFirst ? item[0]?.itemName : item[0]?.itemName2;
    });

    // 
    // 메뉴아이템리스트 에서 아이템명을 추출한다.
    // 
    let setMenuText = "";

    setMenuText = choiceMenuTypes.includes(item.menuType2)
      ? setItemNames.join(", ")
      : setItemNames.join(" + ")

    let finalText = setMenuText.length > maxLen
      ? setMenuText.substring(0, maxLen) + " ..."
      : setMenuText

    // return finalText
    return { result: finalText }
  } catch (e) {
    return { err: { message: e.message } }
  }
}


function getSetmenuTextOkpos({ item, itemOptions2 }) {

  try {

    let itemNames = []
    let setMenuText = ""

    // 
    // OKPOS SIDE MENU
    // 
    if (item.SIDE_MENU_YN === "Y") {
      // 
      // 속성 항목 있음 : 가격이 없다.
      // 
      if (item.SDA_CLS_CD !== "") {
        const sdaCdList = itemOptions2["SdaCdList"] || []

        // 
        // 속성항목리스트 SDA_CLS_CD 포함 아이템 추출 
        // 
        let sdaCdListForItem = sdaCdList.filter(sdaCd => sdaCd.SDA_CLS_CD === item.SDA_CLS_CD)
        itemNames = sdaCdListForItem.map(sdaCd => sdaCd.SDA_NM)
        setMenuText = itemNames.join(" | ")
      }

      // 
      // 선택 메뉴 있음 : 가격이 있을 수 있다.
      // 
      if (item.SDS_GRP_CD !== "") {
        const sdsClsList = itemOptions2["SdsClsList"] || []
        let sdsClsListForItem = sdsClsList.filter(sdsCls => sdsCls.SDS_GRP_CD === item.SDS_GRP_CD)
        itemNames = sdsClsListForItem.map(cls => cls.SDS_CLS_NM)
        setMenuText = itemNames.join(", ")
      }
    }

    // 
    // ONL 옵션 메뉴
    // 
    if (item.optionGroupCode) {
      let optionList = getOnlOptionItems({ item, itemOptions2 })
      itemNames = optionList.map(o => o.name)
      setMenuText = "오늘옵션 - " + itemNames.join(", ")
    }

    let finalText = setMenuText.length > maxLen
      ? setMenuText.substring(0, maxLen) + " ..."
      : setMenuText

    // return finalText
    return { result: finalText }
  } catch (e) {
    if (debug) console.log(e)
    return { err: { message: e.message } }
  }
}


function getSetmenuTextDummypos({ item, itemOptions2 }) {

  try {

    let itemNames = []
    let setMenuText = ""

    // 
    // ONL 옵션 메뉴
    // 
    if (item.optionGroupCode) {
      let optionList = getOptionItemsDummypos({ item, itemOptions2 })
      itemNames = optionList.map(o => o.optionClassName)
      setMenuText = "옵션 - " + itemNames.join(", ")
    }

    let finalText = setMenuText.length > maxLen
      ? setMenuText.substring(0, maxLen) + " ..."
      : setMenuText

    return { result: finalText }
  } catch (e) {
    if (debug) console.log(e)
    return { err: { message: e.message } }
  }
}


function getSetmenuTextOasispos({ item, itemList, itemOptions2, usePosMenuFirst }) {
  try {

    if (item.isset !== "1") return ""

    const setMenus = itemOptions2.setMenuList.filter(setMenu => setMenu.setcode === item.itemCode)
    if (setMenus.length === 0) return ""

    const setItemNames = setMenus.map(setMenu => {
      const items = itemList.filter(item => item.itemCode === setMenu.subcode)
      if (items.length === 1) {
        return usePosMenuFirst ? items[0].itemName : items[0].itemName2;
      } else {
        return ""
      }
    })

    let setMenuText = setItemNames.join(", ")


    let finalText = setMenuText.length > maxLen
      ? setMenuText.substring(0, maxLen) + " ..."
      : setMenuText

    return { result: finalText }
  } catch (e) {
    console.log(e)
    return { err: { message: e.message } }
  }
}


function getSetmenuTextEasypos({ item, itemList, itemOptions2, usePosMenuFirst }) {
  try {

    if (item.KIOSK_SUB_MENU_TYPE !== "Y") return ""

    let setMenuText = ""

    const itemOrders = itemOptions2.itemOrderClassList.filter(o => o.ITEM_CODE === item.itemCode)

    const orderClasses = []
    itemOrders.map(o => {
      const oCls = itemOptions2.orderClassList.filter(oc => oc.ORDER_CLASS_CODE === o.ORDER_CLASS_CODE)
      if (oCls.length === 1) orderClasses.push(oCls[0])
      return null
    })

    // if (debug) {
    //   if (itemOrders.length > 0) {
    //     console.log('='.repeat(50), item.itemCode, item.itemName)
    //     console.log('itemOrders', itemOrders)
    //     console.log('orderClasses', orderClasses)
    //   }
    // }

    const optClassNames = orderClasses.map(oc => oc.ORDER_CLASS_NAME)
    setMenuText = optClassNames.join(", ")

    let finalText = getFinalText(setMenuText)

    return { result: finalText }
  } catch (e) {
    return { err: { message: e.message } }
  }
}


function getFinalText(str) {
  const txt = str.length > maxLen
    ? str.substring(0, maxLen) + " ..."
    : str
  return txt
}
