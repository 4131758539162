import React, { useState, useEffect } from 'react'
import { DataProvider } from '../../components-pos'
import { NavTabs } from '../../components-pos/Layout'

export default function SettingIndex(props) {
  return <DataProvider {...props} Component={Index__} />
}

function Index__(props) {
  const styles = {
    wrapOuter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100vh',
      backgroundColor: '#eef',
    },
    wrapInner: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '80%',
      height: '60vh',
      // border: '1px solid #555',
      borderRadius: 10,
      display: 'flex',
      justifyContent: 'space-between',
    },
  }

  // return (
  //   <>
  //     <Tabs {...props} />
  //     <div style={styles.wrapOuter}>
  //       <div style={styles.wrapInner}>
  //         <h1>종합 관리 페이지</h1>
  //       </div>
  //     </div>
  //   </>
  return <SectionWithTabs {...props} />
  
}

export function Tabs(props) {
  const navItems = [
    { path: '/pos/setting', displayName: 'HOME' },
    { path: '/pos/setting/menus', displayName: 'MENU Setting' },
    { path: '/pos/setting/pos-system', displayName: 'POS Setting' },
  ]
  return <NavTabs navItems={navItems} {...props} />
}

function SectionWithTabs(props) {
  const navItems = [
    // { name: 'back', displayName: 'Back' },
    {
      name: '/pos/setting/menus',
      displayName: 'MENU Setting',
      Component: Comp1,
    },
    {
      name: '/pos/setting/pos-system',
      displayName: 'POS Setting',
      Component: Comp2,
    },
  ]

  const [selectedName, setSelectedName] = useState(navItems[0].name)

  // useEffect(() => {}, [])

  const onClick = (obj) => {
    if(obj.name==="back") {
      props.history.goBack()

    } else {
      setSelectedName(obj.name)
    }
  }

  return (
    <>
      <NavTabs
        navItems={navItems}
        {...props}
        selectedName={selectedName}
        onClick={onClick}
        routeType="name"
      />
      {navItems.map((item, i)=>{
        if(item.name===selectedName) {
          const C = item.Component
          return <C key={i} {...props} />
        } 
      })}
    </>
  )
}

function Comp1(props) {
  return <h2>Menu Setting</h2>
}

function Comp2(props) {
  return <h2>POS Setting</h2>
}
