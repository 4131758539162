import React, { useState, memo, useEffect, useRef } from 'react'
import logo from '../_images/logo_text.png'
import { Button } from '../../../../components'
import { 
  LanguageIcon, 
  ThreeDotsH as NotiIcon, 
  ThreeDotsV as ReceiptIcon 
} from '../../../../components/Icons'
// import OrderInfo from './OrderInfoModal'
// import StaffCall from './StaffCallModal'
// import ChangeLang from './ChangeLang'
// import { getTableInfo } from '../../lib-pos/getTableInfo'

import { debug } from '../../../../settings'

const HeadSectionTablet = memo((props) => {
  const {shopInfo} = props
  
  const styles = {
    headContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '0px 20px',
      height: '100px',
      backgroundColor: 'red',
      boxSizing: 'border-box',
      width: '100%',
      zIndex: '100',
      backgroundColor: 'white',
    },
    headInnerBlock: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
  }

  return (
    <div style={styles.headContainer}>
      <div style={{ ...styles.headInnerBlock, justifyContent: 'flex-start' }}>
        <img src={logo} width={75} />
        <TableName {...props} />
      </div>

      <div style={{ ...styles.headInnerBlock, justifyContent: 'flex-end' }}>
        {/* <OrderInfoBtn {...props} />
        {shopInfo.posName === 'UNIONPOS' && <StaffCallBtn {...props} />}
        <ChangeLangBtn {...props} /> */}
      </div>
    </div>
  )
})

function TableName(props) {
  const { shopInfo, tableInfo } = props

  const onClick = () => {
    if (debug) {
      console.log(props.history)
      const loc = props.history.location
      console.log(loc.pathname, loc.search)
      props.history.push(loc.pathname + '/payment' + loc.search)
    }
  }

  const styles = {
    tableName: {
      fontSize: '1.0rem',
      fontWeight: 'bold',
      color: '#05b8a0',
      margin: '0px 15px',
      padding: '8px 18px',
      borderRadius: '15px',
      backgroundColor: '#EBFAF8',
    },
  }
  return (
    <p style={styles.tableName} onClick={onClick}>
      {shopInfo.shopName} - {tableInfo.tableText}
    </p>
  )
}

// function ChangeLangBtn(props) {
//   const { lang } = props
//   const [visible, setVisible] = useState(false)
//   const onClick = () => {
//     setVisible(true)
//   }
//   return (
//     <>
//       <HeaderBtn title={lang.langName} onClick={onClick}>
//         <LanguageIcon />
//       </HeaderBtn>
//       {visible && (
//         <ChangeLang {...props} visible={visible} setVisible={setVisible} />
//       )}
//     </>
//   )
// }

// function StaffCallBtn(props) {
//   const [visible, setVisible] = useState(false)
//   const onClick = () => {
//     setVisible(true)
//   }
//   return (
//     <>
//       <HeaderBtn title="호출" onClick={onClick}>
//         <NotiIcon />
//       </HeaderBtn>
//       {visible && (
//         <StaffCall {...props} visible={visible} setVisible={setVisible} />
//       )}
//     </>
//   )
// }

// function OrderInfoBtn(props) {
//   const [visible, setVisible] = useState(false)
//   const onClick = () => {
//     setVisible(true)
//   }
//   return (
//     <>
//       <HeaderBtn title="주문내역" onClick={onClick}>
//         <ReceiptIcon />
//       </HeaderBtn>
//       {visible && (
//         <OrderInfo {...props} visible={visible} setVisible={setVisible} />
//       )}
//     </>
//   )
// }

// const HeaderBtn = ({ title, ...rest }) => {
//   return (
//     <Button
//       variant="outline-info"
//       title={title}
//       {...rest}
//       borderRadius={20}
//       style={{ marginLeft: 15 }}
//     />
//   )
// }

export default HeadSectionTablet
