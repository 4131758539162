import { getOnlOptionItems } from "./getOnlOptionItems";
import { debug } from "../settings";


const pos = {
  "OKPOS": { getSetmenuItems: getSetmenuItemsOkpos },
  "EASY_POS": { getSetmenuItems: getSetmenuItemsEasyos },
}

const posList = Object.keys(pos)

/**
 * 
 * @param {*} params { shopInfo, item, itemList, itemOptions2, usePosMenuFirst }
 * @returns 
 */
export function getSetmenuItems({ shopInfo, ...rest }) {
  if (!posList.includes(shopInfo.posName)) return { err: { message: "NOT SUPPORTED" } };
  return pos[shopInfo.posName].getSetmenuItems({ shopInfo, ...rest });
}

function getSetmenuItemsOkpos({ item, itemOptions2 }) {

  try {

    let sdaCdList = []  // OKPOS 속성 
    let sdsClsList = []  // OKPOS 선택 메뉴
    let sdsCdList = []  // OKPOS 선택 메뉴
    let optionClassList = []   // ONL options
    let optionItemList = []   // ONL options

    if (item.SIDE_MENU_YN === "Y") {

      if (item.SDA_CLS_CD !== "") {
        const sdaCds = itemOptions2["SdaCdList"] || []
        sdaCdList = sdaCds.filter(sdaCd => sdaCd.SDA_CLS_CD === item.SDA_CLS_CD)
      }

      if (item.SDS_GRP_CD !== "") {
        const sdsCls_List = itemOptions2["SdsClsList"] || []
        const sdsClsListItem = sdsCls_List.filter(sdsCls => sdsCls.SDS_GRP_CD === item.SDS_GRP_CD)
        const sdsCds = itemOptions2["SdsCdList"] || []

        // clsList 엣 cdList 항목을 추가
        sdsClsListItem.map(cls => {
          const cdList = sdsCds.filter(cd => cd.SDS_CLS_CD === cls.SDS_CLS_CD)
          sdsClsList.push({
            ...cls,
            sdsCdList: cdList
          })
          return null
        })

        // cdList만 추출
        sdsClsList.map(cls => {
          cls.sdsCdList.map(cd => {
            sdsCdList.push(cd)
            return null
          })
          return null
        })
      }
    }

    // 
    // ONL 옵션 메뉴
    // 
    if (item.optionGroupCode) {
      optionClassList = getOnlOptionItems({ item, itemOptions2 })
      optionClassList.map(cls => {
        cls.optionItemList.map(item => {
          optionItemList.push(item)
          return null
        })
        return null
      })
    }

    // return finalText
    return {
      sdaCdList,
      sdsClsList,
      sdsCdList,
      optionClassList,
      optionItemList
    }
  } catch (e) {
    if (debug) console.log(e)
    return { err: { message: e.message } }
  }
}

function getSetmenuItemsEasyos({ shopInfo, item, itemOptions2 }) {

  try {

    let itemOrderClassList = []
    let orderClassList = []
    let orderList = []

    if (item.KIOSK_SUB_MENU_TYPE === "Y") {
      const _itemOrderClassList = itemOptions2.itemOrderClassList
      const _orderClassList = itemOptions2.orderClassList
      const _orderList = itemOptions2.orderList


      itemOrderClassList = _itemOrderClassList.filter(o => o.ITEM_CODE === item.itemCode)

      if (itemOrderClassList.length > 0) {
        _orderClassList.map((cls, i) => {
          const idx = itemOrderClassList.findIndex(o => o.ORDER_CLASS_CODE === cls.ORDER_CLASS_CODE)
          if (idx >= 0) {

            const optItems = _orderList.filter(o => o.ORDER_CLASS_CODE === cls.ORDER_CLASS_CODE)
            const optItemList = optItems.map(item => {
              return { ...item }
            })

            orderClassList.push({
              ...cls,
              orderList: optItemList
            })
          }
          return null
        })
      }

      orderClassList.map(cls => {
        cls.orderList.map(item => {
          orderList.push(item)
          return null
        })
        return null
      })
    }
    return { itemOrderClassList, orderClassList, orderList }
  } catch (e) {
    if (debug) console.log(e)
    return { err: { message: e.message } }
  }
}
