import React, { useEffect, useState } from "react";
import { useStyles, FormInput, ModalForm, FormGroup, FormBox } from "../../../components";
import { updateItem } from "../../../lib-pos";
import { shopItemOptionUpdate, onlItemOptionUpdate } from "../../../lib/ec2-api-lib";
import { updateItemOption } from "../../../lib-pos";
import { sortObjArray2 } from "../../../lib/utils";
import { getSetmenuItems } from "../../../lib-pos/getSetmenuItems";
import { debug } from "../../../settings";

export function SoldoutModal2(props) {
  const {
    mode = "item", // item | clear
    visible, setVisible,
    shopInfo,
    item, itemList, itemOptions2,
    onload,
  } = props;

  const {gStyle} = useStyles()

  const modalTitle = mode === "item"
    ? (item?.itemName + " 품절 세팅")
    : "품절 관리"

  const [isLoading, setIsLoading] = useState(false);

  const [soldoutItems, setSoldoutItems] = useState([]) // soldoutModal과 공통으로 사용하기 위해 array 형태로 함

  /** @OKPOS */
  const [okposSdaCdList, setOkposSdaCdList] = useState([])
  const [okposSdsCdList, setOkposSdsCdList] = useState([])
  const [okposOptionItemList, setOkposOptionItemList] = useState([])

  /** @EASY_POS itemOptions2.orderList */
  const [easyposOrderList, setEasyposOrderList] = useState([])

  useEffect(() => {
    if (mode === "item") {
      setItemMode()
    } else { // clear
      setClearMode()
    }
  }, [mode, item, itemOptions2])

  const setItemMode = () => {
    setSoldoutItems([item])

    if (shopInfo.posName === "OKPOS") {

      const res = getSetmenuItems({ shopInfo, item, itemOptions2 })
      if (res.err) {
        alert(res.err.message);
        return null
      }
      setOkposSdaCdList(res.sdaCdList)
      setOkposSdsCdList(res.sdsCdList)
      setOkposOptionItemList(res.optionItemList)

      if (debug) {
        console.log("=".repeat(50), item.itemCode, item.itemName2)
        console.log('item', item)
        console.log("-".repeat(50), 'ONL optionItemList');
        console.table(res.optionItemList);
        console.log("-".repeat(50), 'selected optionItemList');
        console.table(res.optionItemList);
        console.log("-".repeat(50), 'optionItemList');
        console.table(itemOptions2.optionItemList);
      }
    }

    if (shopInfo.posName === "EASY_POS") {
      const res = getSetmenuItems({ shopInfo, item, itemOptions2 })
      if (res.err) return res
      if (debug) {
        console.table(res.orderList)
      }
      setEasyposOrderList(res.orderList)
    }
  }

  const setClearMode = () => {
    const sItems = itemList.filter(item => item.isSoldout)
    setSoldoutItems(sItems)

    if (shopInfo.posName === "OKPOS") {
      if (itemOptions2?.SdaCdList) {
        const sdaList = itemOptions2.SdaCdList.filter(o => o.isSoldout)
        setOkposSdaCdList(sdaList)
      }
      if (itemOptions2?.SdsCdList) {
        const sdsList = itemOptions2.SdsCdList.filter(o => o.isSoldout)
        setOkposSdsCdList(sdsList)
      }
      if (itemOptions2?.optionItemList) {
        const sOptList = itemOptions2.optionItemList.filter(o => o.isSoldout)
        setOkposOptionItemList(sOptList)
      }
    }

    if (shopInfo.posName === "EASY_POS") {
      if (itemOptions2?.orderList) {
        const sOrderList = itemOptions2?.orderList?.filter(ol => ol?.isSoldout)
        const sorted = sortObjArray2({ arr: sOrderList, key: "ITEM_NAME" })
        setEasyposOrderList(sorted)
      }
    }

  }

  const onSubmit = async () => {
    try {
      // setIsLoading(true);

      if (soldoutItems.length > 0) {
        let errMsg = ""
        await Promise.all(soldoutItems.map(async obj => {
          let res = await updateItem({
            shopInfo,
            id: obj.id,
            body: { isSoldout: obj.isSoldout }
          })
          if (res.err) errMsg += res.err.message + " "
        }))
        if (errMsg !== "") throw Error(errMsg)
      }

      if (shopInfo.posName === "OKPOS") {
        const res = await updateOkpos()
        if (res.err) throw new Error(res.err.message);
      }

      if (shopInfo.posName === "EASY_POS" && easyposOrderList.length > 0) {
        const res = await updateEasypos()
        if (res.err) throw new Error(res.err.message);
      }

      await onload();
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  }

  const updateOkpos = async () => {
    try {
      if (okposSdsCdList.length > 0) {
        const newSdsCdList = props.itemOptions3?.SdsCdList.map(cd => {
          const changed = okposSdsCdList.filter(o => o.SDS_CD === cd.SDS_CD)
          if (changed.length === 1) {
            return { ...cd, isSoldout: changed[0].isSoldout }
          } else {
            return { ...cd }
          }
        })

        const body = { SdsCdList: JSON.stringify(newSdsCdList) };
        let res = await updateItemOption({ shopInfo, body })
        if (res.err) throw new Error(res.err.message);
      }

      if (okposSdaCdList.length > 0) {
        const newSdaCdList = props.itemOptions3?.SdaCdList.map(cd => {
          const changed = okposSdaCdList.filter(o => o.SDA_CD === cd.SDA_CD)
          if (changed.length === 1) {
            return { ...cd, isSoldout: changed[0].isSoldout }
          } else {
            return { ...cd }
          }
        })

        const body = { SdaCdList: JSON.stringify(newSdaCdList) };
        let res = await updateItemOption({ shopInfo, body })
        if (res.err) throw new Error(res.err.message);
      }

      if (okposOptionItemList.length > 0) {
        const newList = props.itemOptions3?.optionItemList.map(cd => {
          const changed = okposOptionItemList.filter(o => o.itemCode === cd.itemCode)
          if (changed.length === 1) {
            return { ...cd, isSoldout: changed[0].isSoldout || false }
          } else {
            return { ...cd }
          }
        })

        let res = await onlItemOptionUpdate({
          shopId: shopInfo.shopId,
          posName: shopInfo.posName,
          body: {
            optionItemList: JSON.stringify(newList),
          }
        })

        if (res.err) throw new Error(res.err.message);
      }

      return {}
    } catch (e) {
      return { err: { message: e.message } }
    }
  }

  const updateEasypos = async () => {
    try {
      const newOrderList = itemOptions2.orderList.map(ol => {
        const changedOrders = easyposOrderList.filter(o => o.ITEM_CODE === ol.ITEM_CODE)
        if (changedOrders.length > 0) {
          return { ...ol, isSoldout: changedOrders[0].isSoldout }
        } else {
          return ol
        }
      })

      const params = {
        shopId: shopInfo.shopId,
        optionName: shopInfo.posName,
        body: { orderList: JSON.stringify(newOrderList) }
      };

      let res = await shopItemOptionUpdate(params);
      if (res.err) throw new Error(res.err.message);

      return {}
    } catch (e) {
      return { err: { message: e.message } }
    }
  }

  const styles = {
    text: { ...gStyle.text, marginBottom: 15, textAlign: "center" }
  }

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setVisible}
      title={modalTitle}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      {(soldoutItems.length === 0 &&
        okposSdaCdList.length === 0 &&
        okposSdsCdList.length === 0 &&
        okposOptionItemList.length === 0 &&
        easyposOrderList.length === 0
      ) ?
        <p style={styles.text}>품절상품이 없습니다.</p>
        :
        <p style={styles.text}>체크해제 후 확인 클릭을 누르면 주문이 재개 됩니다.</p>
      }

      {soldoutItems.length > 0 &&
        <SoldoutEditList
          title="메뉴 아이템"
          codeKey="itemCode"
          labelKey="itemName"
          list={soldoutItems}
          setList={setSoldoutItems}
        />
      }

      {(shopInfo.posName === "OKPOS") &&
        <>
          {okposSdaCdList.length > 0 &&
            <SoldoutEditList
              title="속성 아이템"
              codeKey="SDA_CD"
              labelKey="SDA_NM"
              list={okposSdaCdList}
              setList={setOkposSdaCdList}
            />
          }

          {okposSdsCdList.length > 0 &&
            <SoldoutEditList
              title="선택 아이템"
              codeKey="SDS_CD"
              labelKey="SDS_NM"
              list={okposSdsCdList}
              setList={setOkposSdsCdList}
            />
          }

          {okposOptionItemList.length > 0 &&
            <SoldoutEditList
              title='"오늘" 옵션 아이템'
              codeKey="itemCode"
              labelKey="name"
              list={okposOptionItemList}
              setList={setOkposOptionItemList}
            />
          }

        </>
      }

      {(shopInfo.posName === "EASY_POS" && easyposOrderList.length > 0) &&
        <SoldoutEditList
          title='옵션 아이템(OrderList)'
          codeKey="ITEM_CODE"
          labelKey="ITEM_NAME"
          list={easyposOrderList}
          setList={setEasyposOrderList}
        />
      }

    </ModalForm>
  )
}

export function SoldoutEditList(props) {
  const { list, setList, codeKey, labelKey, title } = props
  const { gStyle } = useStyles()
  const onChange = (e) => {
    const codeValue = e.target.name
    const newList = list.map(o => {
      if (o[codeKey] === codeValue) return { ...o, isSoldout: !o.isSoldout }
      else return o
    })
    setList(newList)
  }
  return (
    <FormGroup>
      <p style={{ ...gStyle.text, textAlign: "left" }}>{title}</p>
      <FormBox>
        {
          list.map((item, i) => {
            return (
              <FormInput
                key={i}
                label={item[labelKey]}
                name={item[codeKey]}
                checked={item.isSoldout}
                onChange={onChange}
                inputType="checkbox"
              />
            )
          })
        }
      </FormBox>
    </FormGroup>
  )
}
