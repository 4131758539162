import React, { useEffect, useState } from "react";
import { Page, LoaderFullPage } from "../../../components";
import { useSelector, useDispatch } from "react-redux";
import { setItemOptions3 } from "../../../redux/items";
import { Tab, Tabs } from "react-bootstrap";
import { getItemOptions, parseItemOptions } from "../../../lib-pos";
import PosTableList from "./PosTableList";
import OKPosSideMenus from "./OkPosOptionMenus";
import OnlOptionMenus from "./OnlOptionMenus";
import EasyPosOptionMenus from "./EasyPosOptionMenus";
import { debug } from "../../../settings";
import DposTableList from "./DposTableList";

export default function OptionMenus(props) {
  const { shopInfo } = useSelector(state => state.shop);
  const { itemList3, itemOptions3 } = useSelector(state => state.items)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  // 
  // set UI for POS
  //
  const [editPosOptions, setEditPosOptions] = useState(false)
  const [editTableList, setEditTableList] = useState(false)
  const [editOnlOptions, setEditOnlOptions] = useState(false)
  const [editEasyOptions, setEditEasyOptions] = useState(false)

  const [editDposTableList, setEditDposTableList] = useState(false)

  const posName = shopInfo.posName

  useEffect(() => {
    if (posName === "OKPOS") {
      setEditPosOptions(true)
      setEditTableList(true)
      setEditOnlOptions(true)
    }

    if (
      posName === "UNIONPOS"
      || posName === "OASIS_POS"
      || posName === "EASY_POS"
    ) {
      setEditTableList(true)
    }

    if (posName === "DUMMY_POS") {
      setEditOnlOptions(true)
      setEditDposTableList(true)
    }

    if (posName === "EASY_POS") {
      setEditEasyOptions(true)
    }

  }, [shopInfo])

  const onload = async () => {
    try {
      setIsLoading(true)
      let res = await getItemOptions({ shopInfo })
      if (res.err) throw Error(res.err.message)

      const _options2 = parseItemOptions({
        shopInfo,
        itemOptions: res.result.Item
      })
      // setItemOptions2(_options2)
      dispatch(setItemOptions3({ itemOptions3: _options2 }))

    } catch (e) {
      if (debug) console.log(e)
      alert(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  const otherProps = {
    shopInfo, onload,
    itemList3, itemOptions3,
    isLoading, setIsLoading,
    posName
  }

  return (
    <Page>
      <Tabs
        defaultActiveKey={
          (posName === "UNIONPOS" || posName === "OASIS_POS")
            ? "posTable"
            : (posName === "EASY_POS")
              ? "easyOptions" : (posName === "OKPOS")
                ? "posOptions"
                : "onlOptions"
        }
        id="onl-option-menus"
        className="mb-3"
      >
        {editTableList &&
          <Tab eventKey="posTable" title="포스 테이블">
            <br />
            <PosTableList {...props} {...otherProps} />
          </Tab>
        }

        {/** @OKPO_options  */}
        {(posName==="OKPOS") &&
          <Tab eventKey="posOptions" title="포스 선택옵션(SDS)">
            <br />
            <OKPosSideMenus {...props} {...otherProps} type="sds" />
          </Tab>
        }

        {(posName==="OKPOS") &&
          <Tab eventKey="posOptionsSda" title="포스 속성옵션(SDA)">
            <br />
            {/* <OkPosSda {...props} {...otherProps} /> */}
            <OKPosSideMenus {...props} {...otherProps} type="sda" />
          </Tab>
        }

        {editOnlOptions &&
          <Tab eventKey="onlOptions" title='"오늘" 옵션메뉴'>
            <br />
            <OnlOptionMenus {...props} {...otherProps} />
          </Tab>
        }

        {editDposTableList &&
          <Tab eventKey="dposTableList" title='테이블 리스트'>
            <br />
            <DposTableList {...props} {...otherProps} />
          </Tab>
        }

        {editEasyOptions &&
          <Tab eventKey="easyOptions" title='옵션메뉴 Soldout 세팅'>
            <br />
            <EasyPosOptionMenus {...props} {...otherProps} />
          </Tab>
        }

      </Tabs>

      {isLoading && <LoaderFullPage />}
    </Page>
  )
}
