import React from 'react'
import { DataProvider } from '../components-pos'
import {
  House,
  Setting,
  GraphUpArrow,
  DoorClosed,
  UserAdd,
} from '../../../components/Icons'
import HeaderLogo from '../../../assets/images/store-web-logo.png'

export default function PosHome(props) { 
  return <DataProvider {...props} Component={__PosHome} />
}


function __PosHome(props) {
  // const { shopInfo, selectedLanguage } = useSelector((state) => state.shop)
  // const { itemList3, itemOptions3 } = useSelector((state) => state.items)
  // const { cartItems } = useSelector((state) => state.cart)
  // const { isMobileDevice, isTablet, isMobilePhone } = useSelector(
  //   (state) => state.platform,
  // )
  // const { gStyle, colors } = useStyles()

  // // const lang = setLanguage(selectedLanguage)
  // // const texts = lang

  // const allProps = {
  //   ...props,
  //   ver: 'v3',
  //   shopInfo,
  //   tableNo: '007',
  //   itemList: itemList3,
  //   itemOptions: itemOptions3,
  //   itemOptions2: itemOptions3,
  //   cartItems,
  //   isMobileDevice,
  //   isTablet,
  //   isMobilePhone,
  //   // lang, texts,

  //   gStyle,
  //   colors,
  // }

  const styles = {
    wrapOuter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100vh',
      backgroundColor: '#fff',
    },
    wrapInner: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '80%',
      height: '60vh',
      // border: '1px solid #555',
      borderRadius: 10,
      display: 'flex',
      justifyContent: 'space-between',
    },
  }

  return (
    <div style={styles.wrapOuter}>
      <div style={styles.wrapInner}>
        
        {/* <DataProvider Component={StoreInfoBox} /> */}
        <StoreInfoBox {...props} />
        <BtnBox {...props} />
      </div>
    </div>
  )
}

function StoreInfoBox(props) {
  const styles = {
    wrap: {
      flex: 4,
      width: '100%',
      height: '60vh',
      backgroundColor: 'transparent',
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: 'azure',
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    title: {
      ...props.gStyle.title,
      ...props.gStyle.highLight,
      fontSize: '2.5rem',
      color: props.colors.info,
      marginBottom: 15,
    },
    line: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    text: {
      ...props.gStyle.text,
      marginBottom: 5,
    },
  }

  const storeInfoProps = [
    { name: 'storeCode', displayName: '가맹코드' },
    { name: 'van', displayName: '밴사' },
    { name: 'licenseNo', displayName: '사업자번호' },
    { name: 'tel', displayName: '전화번호' },
    { name: 'shopName', displayName: '매장명' },
    { name: 'posNo', displayName: 'POS번호' },
    { name: 'created', displayName: '개점일자' },
    { name: 'closingDate', displayName: '최종마감일' },
  ]
  return (
    <div style={styles.wrap}>
      <div>
        <h2 style={styles.title}>NO. 1 PARTNER</h2>
        <img src={HeaderLogo} width="120px" />
      </div>
      <div>
        {storeInfoProps.map((attr, i) => {
          let value = props.shopInfo[attr.name] || ''
          if (attr.name === 'created') {
            value = new Date(props.shopInfo[attr.name]).toLocaleDateString()
          }
          return (
            <div style={styles.line} key={i}>
              <p style={styles.text}>{attr.displayName}</p>
              <p style={styles.text}>{value}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function BtnBox(props) {
  const styles = {
    wrap: {
      flex: 5,
      width: '100%',
      height: '60vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'orange',
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
    innerWrap: {
      width: '90%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 15,
    },
    btnWrap: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginTop: 30,
    },
  }

  const btns = [
    {
      name: 'closing',
      displayName: '영업마감',
      icon: DoorClosed,
      onClick: () => alert('영업마감'),
    },
    {
      name: 'salesAnal',
      displayName: '매출분석',
      icon: GraphUpArrow,
      onClick: () => alert('매출분석'),
    },
    {
      name: 'settings',
      displayName: '종합관리',
      icon: Setting,
      onClick: () => props.history.push('/pos/setting'),
    },
    {
      name: 'staffs',
      displayName: '출퇴근',
      icon: UserAdd,
      onClick: () => alert('출퇴근'),
    },
  ]

  return (
    <div style={styles.wrap}>
      <div style={styles.innerWrap}>
        <HomeBtn
          {...props}
          onClick={() => {
            props.history.push('/pos/sale')
          }}
        />
        <div style={styles.btnWrap}>
          {btns.map((b, i) => {
            return <RoundBtn key={i} {...props} {...b} Component={b.icon} />
          })}
        </div>
      </div>
    </div>
  )
}

function HomeBtn(props) {
  const styles = {
    wrap: {
      width: '90%',
      height: '70px',
      borderRadius: 5,
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      ...props.gStyle.title,
      margin: 0,
      padding: 10,
    },
  }
  return (
    <div style={styles.wrap} onClick={props.onClick}>
      <House style={{ ...props.gStyle.title }} />
      <p style={styles.text}>매장영업</p>
    </div>
  )
}

function RoundBtn(props) {
  const { Component } = props
  const w = 80
  const styles = {
    wrap: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: 20,
    },
    rndBtn: {
      width: w + 'px',
      height: w + 'px',
      border: '1px solid #aaa',
      borderRadius: w / 2,
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {},
  }
  return (
    <div style={styles.wrap}>
      <div style={styles.rndBtn} onClick={props.onClick}>
        <Component style={{ ...props.gStyle.title, fontSize: '2.0rem' }} />
      </div>
      <p style={{ ...props.gStyle.subtitle, marginTop: 5 }}>
        {props.displayName}
      </p>
    </div>
  )
}
