// import conf from "./conf";

const langMap = {
  "KO": {
    orderList: '주문내역',
    orderListTitle1: ' 총 주문내역',
    orderListTitle2: ' 번 테이블 총 주문내역',
    callStaff: '직원호출',
    langName: '',
    addBtn: '+ 더 담으러 가기',
    orderBtn: '주문하기',
    backBtn: '뒤로가기',
    goCart: '담기',
    qty: '수 량 : ',
    ok: '확 인',
    cancel: '취 소',
    inStore: '매장식사',
    totalPrice: '총 주문금액',
    emptyCart: '카트가 비었습니다.',
    oneMin1: '주문시간이 1분 남았습니다.',
    oneMin2: `만약 1분이 지나 주문이 안되면 다시 태깅후 주문해주시길 부탁드립니다.`,
    orderTimeErr: '주문시간이 초과 되었습니다.\n다시 태깅해 주세요.',
    callSuccess: '직원을 호출하였습니다.',
    callFailure: '직원 호출에 실패 했습니다.\n다시 시도해주세요.',
    orderComplete: '주문이 완료되었습니다.\n감사합니다.',
    orderFail: '주문이 실패하였습니다.\n다시 태깅 후 주문해주세요.',
    won: '원',
    warnning: '주의!',
    essential: '필수선택',
    cart: '장바구니',
    tabletTotalPrice: '총 ',
    tabletAddButton: '더 담기',
    menuError: '메뉴 목록을 불러오는데 실패 했습니다.',
  },
  "EN": {
    orderList: 'Order list',
    orderListTitle1: 'Total order list',
    orderListTitle2: 'Total order list',
    callStaff: 'Call Server',
    langName: '',
    addBtn: '+ Continue Shopping',
    orderBtn: 'Order',
    backBtn: 'Back',
    goCart: 'Add to Cart',
    qty: 'Quantity : ',
    ok: 'O K',
    cancel: 'Cancel',
    inStore: 'Dine in',
    totalPrice: 'Total amount',
    emptyCart: 'Your cart is empty.\nPlease add items to proceed with your order.',
    oneMin1: 'Please order within 1 minute.',
    oneMin2: `If the order is not placed within 1 minute, please re-tag and place your order`,
    orderTimeErr: 'Sorry, but the order time has exceeded.\nPlease re-tap.',
    callSuccess: 'The Server has been called.',
    callFailure: 'Failed to call the server.\nPlease try again.',
    orderComplete: 'The order has been successfully placed.',
    orderFail: 'The order was not successful.\nPlease re-tap and order.',
    won: '₩',
    warnning: 'Warnning!!',
    essential: 'Required',
    cart: 'Cart',
    tabletTotalPrice: 'Total: ',
    tabletAddButton: 'Continue Shopping',
    menuError: 'Failed to load the menu list!'
  },
  "JP": {
    orderList: 'Order list',
    orderListTitle1: 'Total order list',
    orderListTitle2: 'Total order list',
    callStaff: 'Call Server',
    langName: '',
    addBtn: '+ Continue Shopping',
    orderBtn: 'Order',
    backBtn: 'Back',
    goCart: 'Add to Cart',
    qty: 'Quantity : ',
    ok: 'O K',
    cancel: 'Cancel',
    inStore: 'Dine in',
    totalPrice: 'Total amount',
    emptyCart: 'Your cart is empty.\nPlease add items to proceed with your order.',
    oneMin1: 'Please order within 1 minute.',
    oneMin2: `If the order is not placed within 1 minute, please re-tag and place your order`,
    orderTimeErr: 'Sorry, but the order time has exceeded.\nPlease re-tap.',
    callSuccess: 'The Server has been called.',
    callFailure: 'Failed to call the server.\nPlease try again.',
    orderComplete: 'The order has been successfully placed.',
    orderFail: 'The order was not successful.\nPlease re-tap and order.',
    won: '₩',
    warnning: 'Warnning!!',
    essential: 'Required',
    cart: 'Cart',
    tabletTotalPrice: 'Total: ',
    tabletAddButton: 'Continue Shopping',
  },
  "CN": {
    orderList: '订单记录',
    orderListTitle1: '总订单记录',
    orderListTitle2: '总订单记录',
    callStaff: '呼叫服务员',
    langName: '',
    addBtn: '+ 继续购物',
    orderBtn: '点菜',
    backBtn: '返回',
    goCart: '加入购物车',
    qty: '数量 : ',
    ok: '确定',
    cancel: '取消',
    inStore: '堂食',
    totalPrice: '总金额',
    emptyCart: '您的购物车是空的。\n请添加商品以继续下单。',
    oneMin1: '请在1分钟内下单。',
    oneMin2: '如果一分钟内没有下单, 请重新点击并下单。',
    orderTimeErr: '订单时间已超时，请重新点击。',
    callSuccess: '服务员已经被呼叫了。',
    callFailure: '呼叫服务员失败，请重试。',
    orderComplete: '订单已成功下单。',
    orderFail: '订单未成功，请重新点击下单。',
    won: '₩',
    warnning: '警告!!',
    essential: '必选项',
    cart: '购物车',
    tabletTotalPrice: '总: ',
    tabletAddButton: '继续购物',
  },
}

/** @TODO conf 에서 제거할 것 */
const langIconMap = {
  "KO": "한",
  "KR": "한",
  "EN": "A",
  "JA": "あ",
  "JP": "あ",
  "ZH": "中",
  "CN": "中",
  "ES": "ES",
  "FR": "FR",
  "DE": "DE"
}

export function setLanguageLocale(lang) {
  if (lang === 'KR') lang = 'KO'
  else if (!langMap[lang]) lang = 'KO'
  return lang;
}

export function setLanguage(lang) {
  if (lang === 'KR') lang = 'KO'
  let temp = langMap[lang || 'KO'];
  if (!temp) {
    temp = langMap["KO"];
    lang = "KO";
  }
  temp = { ...temp, langName: langIconMap[lang] };
  return temp;
}

export function setLangs(posName, itemInfo, lang) {
  let itemList = Object.assign([], itemInfo.sortedItems);
  let grpList = Object.assign([], itemInfo.displayGrpList);
  let choiceList = Object.assign([], itemInfo.choiceMenuList);

  grpList = grpList.map((grp) => {
    let newGrp = Object.assign({}, grp);
    newGrp = changeGrpName(newGrp, lang);
    return newGrp;
  });
  itemList = itemList.map((item) => {
    let newItem = Object.assign({}, item);
    newItem = changeItemName(newItem, lang);
    return newItem;
  });
  choiceList = choiceList.map((item) => {
    let newItem = Object.assign({}, item);
    newItem = changeItemName(newItem, lang);
    return newItem;
  });
  itemList.map((item) => {
    if (posName === 'UNIONPOS') {
      let temp = grpList.filter((grp) => grp.GrpCode === item.groupCode);
      let result = {
        ...item.itemGroup[0],
        name: temp[0]?.displayName,
      }
      item.itemGroup = [];
      item.itemGroup.push(result);
    } else if (posName === 'OKPOS') {
      item.itemGroup = item.itemGroup.map((ig) => {
        let updatedIg = { ...ig };
        grpList.forEach(grp => {
          if (grp.GrpCode === ig.grpCode) {
            updatedIg = {
              ...ig,
              name: grp.displayName,
            };
          }
        });
        return updatedIg;
      });
    }
  });

  choiceList = choiceList.map((item) => {
    let temp = grpList.filter((grp) => grp.GrpCode === item.groupCode);
    item.itemGroup = (temp[0]?.displayName);

    return item;
  });

  const displayMenuList = grpList.map((grp) => {
    grp.menus = grp.menus.map((item) => {
      let newItem = Object.assign({}, item);
      newItem = changeItemName(newItem, lang);
      return newItem;
    });
    return grp.menus;
  });

  return { grpList, itemList, choiceList, displayMenuList };
}

function changeItemName(item, lang) {
  let name = {}
  switch (lang) {
    case 'KO':
    case 'KR':
      name = item.nameLang?.KO;
      break;
    case 'EN':
      name = item.nameLang?.EN;
      break;
    case 'JA':
    case 'JP':
      name = item.nameLang?.JP;
      break;
    case 'CN':
    case 'ZH':
    case 'CH':
      name = item.nameLang?.CN;
      break;
    default:
      break;
  }
  name ?
    item.itemName2 = name :
    item.itemName2 = item.backupName;
  return item;
}

function changeGrpName(grp, lang) {
  let name = {}
  switch (lang) {
    case 'KR':
    case 'KO':
      name = grp.nameLang?.KO;
      break;
    case 'EN':
      name = grp.nameLang?.EN;
      break;
    case 'JP':
    case 'JA':
      name = grp.nameLang?.JP;
      break;
    case 'CN':
    case 'ZH':
    case 'CH':
      name = grp.nameLang?.CN;
      break;
    default:
      break;
  }
  name ?
    grp.displayName = name :
    grp.displayName = grp.backupName;

  return grp;
}

export const targetLangs = [
  { name: 'KR', displayName: '한 국 어' },
  { name: 'EN', displayName: 'English' },
  { name: 'CN', displayName: '中国大陆' },
  { name: 'JP', displayName: '日 本 語' },
]