import React, { useState, memo, useEffect, useRef } from 'react'
import { getTableInfo } from '../../../../lib-pos/getTableInfo'
import HeadSectionTablet from './HeadSectionTablet'
import HeadSectionMobile from './HeadSectionMobile'

const HeadSection = memo((props) => {
  const { shopInfo, tableNo, itemOptions, setAlert, isMobilePhone } = props
  const [tableInfo, setTableInfo] = useState({})

  useEffect(() => {
    function start() {
      try {
        let res = getTableInfo({ shopInfo, tableNo, itemOptions2: itemOptions })
        if (res.err) throw Error(res.err.message)
        setTableInfo(res.table)
      } catch (e) {
        setAlert(e.message)
      }
    }
    start()
  }, [itemOptions])

  const allProps = { ...props, tableInfo }

  const styles = {
    wrap: {
      // position: 'fixed', // mobile
      // position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '200px',
      zIndex: '0',
    },
  }

  return (
    <>
      {isMobilePhone ? (
        <div style={styles.wrap}>
          <HeadSectionMobile {...allProps} />
        </div>
      ) : (
        <HeadSectionTablet {...allProps} />
      )}
    </>
  )
})

export default HeadSection
