import { Button } from '../../../components'

export function NavTabs(props) {
  const {
    style,
    routeType = 'path', // name | path

    // routeType path인 경우  [{path, displayName] }]
    // routeType name인 경우  [{path, displayName] }]
    navItems, 
    direction = 'row', // row | column
    selectedName, // selected name,
    onClick,
  } = props

  // const styles = style || {
  //   margin: "10px 20px 0",
  //   padding: padding || "20px 0",
  //   width: "100%",
  //   backgroundColor: bgColor || "transparent", // #efeff6
  //   flex: 1
  // };

  const commonStyles = {
    navTabs: {
      padding: 5,
      // backgroundColor: "#eee",
      // borderRight: '2px solid #eee',
      display: 'flex',
      flexDirection: direction,
      alignItems: 'center',
      ...style,
    },
  }
  return (
    <div style={commonStyles.navTabs}>
      {navItems.map((item, i) => {
        let variant = 'outline-secondary'
        if (routeType === 'path' && props.location.pathname === item.path) {
          variant = 'info'
        }

        if (routeType === 'name' && item.name === selectedName) {
          variant = 'info'
        }
        return (
          <Button
            style={{ marginRight: 5 }}
            key={i}
            title={item.displayName}
            variant={variant}
            onClick={() => {
              if (routeType === 'path') props.history.push(item.path)
              if (routeType === 'name') onClick(item)
            }}
          />
        )
      })}
    </div>
  )
}
