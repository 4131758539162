import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ChangeQty, Price, Divider } from '../components/Miscs'
import { getPriceStr } from '../../../../lib/utils'
// import { ReactComponent as CloseButton } from '../../assets/icons/close.svg'
import { Close } from '../../../../components/Icons'
import { removeCartItem, updateItemCount } from '../../../../redux/cart'
import { getItemName } from '../../../../lib-pos'
import { Text } from '../components/Miscs'

export default function CartItem(props) {
  const dispatch = useDispatch()
  const { itemOptions2, cartItem, lang, shopInfo } = props

  const [itemCount, setItemCount] = useState(cartItem.count)
  const [itemName, setItemName] = useState('')
  const [optionNames, setOptionNames] = useState('')
  const [totalPrice, setTotalPrice] = useState(0)

  useEffect(() => {
    const usePosMenuFirst = itemOptions2.menuSettings?.usePosMenuFirst || false
    const iName = getItemName({
      shopInfo,
      usePosMenuFirst,
      item: cartItem,
      selectedLanguage: 'KR',
    })
    setItemName(iName)

    let optNameList = []
    let optPrice = 0
    cartItem.options.map((opt) => {
      optNameList.push(opt.optionItemName)
      optPrice += opt.optPrice // add cart시 item에 추가 하였슴.
    })

    const optNames = '(' + optNameList.join(' , ') + ')'

    if (optNameList.length > 0)
      setOptionNames(`${optNames} ${getPriceStr(optPrice)}원`)
    else setOptionNames(' ')

    setTotalPrice((parseInt(cartItem.price) + optPrice) * cartItem.count)
  }, [cartItem])

  /** @TODO remove 및 update시 itemCode를 기준으로 바꿀 것 */
  useEffect(() => {
    dispatch(updateItemCount({ itemName: cartItem.itemName, count: itemCount }))
  }, [itemCount])

  const onDelete = () => {
    dispatch(removeCartItem({ itemName: cartItem.itemName }))
  }

  const styles = {
    nameWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 15,
    },
    textWrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    nameText: {
      margin: 0,
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#2b2b2b',
      textAlign: 'left',
    },
    optionText: {
      margin: 0,
      fontSize: '12px',
      fontWeight: '350',
      marginTop: '4px',
    },
    closeBtnWrap: { width: '20px', height: '20px' },
    qty: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 13,
    },
  }

  return (
    <div>
      <div style={styles.nameWrap}>
        <div style={styles.textWrap}>
          <Text isBold value={itemName} />
          <Text value={optionNames} />
        </div>
        <div onClick={onDelete}>
          <Close width="20px" height="20px" fill="#b2b2b2" />
        </div>
      </div>

      <div style={styles.qty}>
        <Price price={totalPrice} lang={lang} />
        <ChangeQty
          size="sm"
          itemCount={itemCount}
          setItemCount={setItemCount}
        />
      </div>
      <Divider />
    </div>
  )
}
