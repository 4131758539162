import React, { useState, memo, useEffect, useRef } from 'react'
import logo from '../_images/logo_text.png'
import { Button } from '../../../../components'
import { 
  LanguageIcon, 
  ThreeDotsH as NotiIcon, 
  ThreeDotsV as ReceiptIcon 
} from '../../../../components/Icons'
// import OrderInfo from './OrderInfoModal'
// import StaffCall from './StaffCallModal'
// import ChangeLang from './ChangeLang'
// import { getTableInfo } from '../../lib-pos/getTableInfo'
// import HeadSectionTablet from './HeadSectionTablet'

import { getTnUrl } from '../../../../lib/utils'
import { debug } from '../../../../settings'

const HeadSectionMobile = memo((props) => {
  const { shopInfo } = props

  const shopImg = getTnUrl(shopInfo.image)
  // const img = shopImg.split(' ').join('%20')
  // console.log(img)

  const styles = {
    headContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: '200px',
      boxSizing: 'border-box',
      width: '100%',
      backgroundColor: 'white',
      backgroundImage: `url(${shopInfo.image.split(' ').join('%20')})`, // 이미지 url에 띄어쓰기가 있을경우 해결.
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    headInnerBlock: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
  }
  return (
    <div style={styles.headContainer}>
      <div style={{ ...styles.headInnerBlock, justifyContent: 'flex-start' }}>
        {/* <img src={shopInfo.image} width={75} /> */}
        <TableName {...props} />
      </div>

      <div style={{ ...styles.headInnerBlock, justifyContent: 'flex-end' }}>
        {/* <OrderInfoBtn {...props} />
        {shopInfo.posName === 'UNIONPOS' && <StaffCallBtn {...props} />}
        <ChangeLangBtn {...props} /> */}
      </div>
    </div>
  )
})

function TableName(props) {
  const { tableInfo } = props

  // console.log('tableInfo', tableInfo)

  const onClick = () => {
    if (debug) {
      // console.log(props.history)
      // const loc = props.history.location
      // console.log(loc.pathname, loc.search)
      // props.history.push(loc.pathname + '/payment' + loc.search)
    }
  }

  const styles = {
    tableName: {
      fontSize: '1.0rem',
      fontWeight: 'bold',
      color: '#05b8a0',
      margin: '0px 15px',
      padding: '8px 18px',
      borderRadius: '15px',
      backgroundColor: '#EBFAF8',
    },
  }
  return (
    <p style={styles.tableName} onClick={onClick}>
      {' '}
      {tableInfo.tableText}{' '}
    </p>
  )
}

// function ChangeLangBtn(props) {
//   const { lang } = props
//   const [visible, setVisible] = useState(false)
//   const onClick = () => {
//     setVisible(true)
//   }
//   return (
//     <>
//       <HeaderBtn title={lang.langName} onClick={onClick}>
//         <LanguageIcon />
//       </HeaderBtn>
//       {visible && (
//         <ChangeLang {...props} visible={visible} setVisible={setVisible} />
//       )}
//     </>
//   )
// }

// function StaffCallBtn(props) {
//   const [visible, setVisible] = useState(false)
//   const onClick = () => {
//     setVisible(true)
//   }
//   return (
//     <>
//       <HeaderBtn title="호출" onClick={onClick}>
//         <NotiIcon />
//       </HeaderBtn>
//       {visible && (
//         <StaffCall {...props} visible={visible} setVisible={setVisible} />
//       )}
//     </>
//   )
// }

// function OrderInfoBtn(props) {
//   const [visible, setVisible] = useState(false)
//   const onClick = () => {
//     setVisible(true)
//   }
//   return (
//     <>
//       <HeaderBtn title="주문내역" onClick={onClick}>
//         <ReceiptIcon />
//       </HeaderBtn>
//       {visible && (
//         <OrderInfo {...props} visible={visible} setVisible={setVisible} />
//       )}
//     </>
//   )
// }

const HeaderBtn = ({ title, ...rest }) => {
  return (
    <Button
      variant="outline-info"
      title={title}
      {...rest}
      borderRadius={20}
      style={{ marginLeft: 15, background: "transparent" }}
    />
  )
}

export default HeadSectionMobile
