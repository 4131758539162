import React, { useEffect, useState } from "react";
import { CardContainer, useStyles, CustomTable } from "../../../components";
import { CustomTable2 } from "../../../components/Tables2";
import { Pencil, Sorting } from "../../../components/Icons";
import { OKPosOptionsModal } from "./OKPosOptionModal";
import { debug } from "../../../settings";

const tableHeaders = {

  // SDA
  SdaClsList: [
    { name: "SDA_CLS_CD", displayName: "코드(SDA_CLS_CD)" },
    { name: "SDA_CLS_NM", displayName: "이름" },
    { name: "USE_YN", displayName: "사용여부" },
    { name: "required", displayName: "필수여부", type: "boolean" },
  ],
  SdaCdList: [
    { name: "SDA_CLS_CD", displayName: "분류코드(SDA_CLS_CD)" },
    { name: "SDA_CD", displayName: "코드(SDA_CD)" },
    { name: "SDA_NM", displayName: "이름" },
    // { name: "PROD_CD", displayName: "아이템코드" },
    // { name: "SDS_PROD_UPRC", displayName: "가격", type: "number" },
    { name: "isSoldout", displayName: "품절" },
  ],

  // SDS
  SdsGrList: [
    { name: "SDS_GRP_CD", displayName: "그룹코드(SDS_GRP_CD)" },
    { name: "SDS_GRP_NM", displayName: "이름" },
    { name: "USE_YN", displayName: "사용여부" },
  ],
  SdsClsList: [
    { name: "SDS_GRP_CD", displayName: "그룹코드(SDS_GRP_CD)" },
    { name: "SDS_CLS_CD", displayName: "코드(SDS_CLS_CD)" },
    { name: "SDS_CLS_NM", displayName: "이름" },
    { name: "USE_YN", displayName: "사용여부" },
    { name: "required", displayName: "필수여부", type: "boolean" },
  ],
  SdsCdList: [
    { name: "SDS_CLS_CD", displayName: "분류코드(SDS_CLS_CD)" },
    { name: "SDS_CD", displayName: "코드(SDS_CD)" },
    { name: "SDS_NM", displayName: "이름" },
    // { name: "PROD_CD", displayName: "아이템코드" },
    { name: "SDS_PROD_UPRC", displayName: "가격", type: "number" },
    { name: "isSoldout", displayName: "품절" },
  ],
  // checkObj: { name: "checked", displayName: "선택", type: "checkbox" },
  // sorting: { name: "sorting", displayName: "순서", type: "sorting" },
}

export const optionTypes = {
  sdsCls: "SdsCls",  sdsCd: "SdsCd",
  sdaCls: "SdaCls", sdaCd: "SdaCd",
}

/** @OK_POS_SDS */

export default function OkPosSdaSds(props) {
  const { itemOptions3, type = "sds" } = props
  const title = `포스 옵션메뉴 세팅 (${type})`;
  
  const [sdaClsList, setSdaClsList] = useState([])
  const [sdaCdList, setSdaCdList] = useState([])

  const [sdsGrList, setSdsGrList] = useState([])
  const [sdsClsList, setSdsClsList] = useState([])
  const [sdsCdList, setSdsCdList] = useState([])

  const [visibleSdsEdit, setVisibleSdsEdit] = useState(false)
  const [optionType, setOptionType] = useState(optionTypes.sdsCls)

  useEffect(() => {
    const start = () => {
      if(type==="sds") {
        setSdsGrList(itemOptions3?.SdsGrList || [])
        setSdsClsList(itemOptions3?.SdsClsList || [])
        setSdsCdList(itemOptions3?.SdsCdList || [])
      }

      if(type==="sda") {
        setSdaClsList(itemOptions3?.SdaClsList || [])
        setSdaCdList(itemOptions3?.SdaCdList || [])
      }
    }
    start();
  }, [type, itemOptions3])

  const modalProps = {
    // optionType,
    sdaClsList, sdaCdList,
    sdsClsList, sdsCdList,
    tableHeaders,
  }

  return (
    <div>
      {type === "sds" &&
        <CardContainer
          title={title}
          onClickBack={() => props.history.goBack()}
        >
          <DataTableBlock
            tableHeader={tableHeaders.SdsGrList}
            rowItems={sdsGrList}
            setRowItems={setSdsGrList}
            title="선택그룹 리스트"
          />

          <DataTableBlock
            tableHeader={tableHeaders.SdsClsList}
            rowItems={sdsClsList}
            setRowItems={setSdsClsList}
            onClickItem={() => {
              setOptionType(optionTypes.sdsCls)
              setVisibleSdsEdit(true)
            }}
            title="선택분류 리스트"
          />

          <DataTableBlock
            tableHeader={tableHeaders.SdsCdList}
            rowItems={sdsCdList}
            setRowItems={setSdsCdList}
            onClickItem={() => {
              setOptionType(optionTypes.sdsCd)
              setVisibleSdsEdit(true)
            }}
            title="선택아이템 리스트"
          />
        </CardContainer>
      }

      {type === "sda" &&
        <CardContainer
          title={title}
          onClickBack={() => props.history.goBack()}
        >
          <DataTableBlock
            tableHeader={tableHeaders.SdaClsList}
            rowItems={sdaClsList}
            setRowItems={setSdaClsList}
            onClickItem={() => {
              setOptionType(optionTypes.sdaCls)
              setVisibleSdsEdit(true)
            }}
            title="속성분류 리스트"
          />

          <DataTableBlock
            tableHeader={tableHeaders.SdaCdList}
            rowItems={sdaCdList}
            setRowItems={setSdaCdList}
            onClickItem={() => {
              setOptionType(optionTypes.sdaCd)
              setVisibleSdsEdit(true)
            }}
            title="속성아이템 리스트"
          />
        </CardContainer>
      }

      <OKPosOptionsModal
        {...props}
        {...modalProps}
        visible={visibleSdsEdit}
        setVisible={setVisibleSdsEdit}
        optionType={optionType}
      />
    </div>
  )
}

function DataTableBlock(props) {
  const { gStyle, colors } = useStyles()

  const styles = {
    subTitleBar: {
      margin: 5,
      display: "flex",
      alignItems: "center"
    },
    subTitle: {
      ...gStyle.subtitle
    },
    iconPlus: { ...gStyle.subtitle, color: colors.primary, marginLeft: "20px" },
  }

  return (
    <div style={{ marginTop: 15 }}>
      <div style={styles.subTitleBar}>
        <p style={styles.subTitle}>{props.title}</p>
        {props.onClickItem &&
          <Pencil
            style={styles.iconPlus}
            onClick={props.onClickItem}
          />
        }
      </div>
      <CustomTable2
        tableHeader={props.tableHeader}
        rowItems={props.rowItems}
        setRowItems={props.setRowItems}
      />
    </div>
  )
}
