import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { capitalize } from "../lib/utils";
import { useSelector } from "react-redux";
import HeaderLogo from '../assets/images/store-web-logo.png';
import {
  House,
  Signout,
  Signin,
  UserAdd,
  UserSetting,
  Setting,
  ListTable,
  PayCard,
  QrCode
} from "../components/Icons";
import { debug } from "../settings";
import { ec2Debug, oasispos, payApiDebug, dqrSettingDebug } from "../settings/appSetting";
import { routePaths } from "./routePaths";
import "./NavigationBar2.css";

/** @TODO drop down 위치 조정 */
const paths = routePaths

/** **********************************************************************************
 * 
 * @Navbar_style은_NavigationBar2_css_에서만_수정할_것
 * 
 ************************************************************************************/
function NavigationBar(props) {
  const { handleLogout } = props;

  const { user, isSigned } = useSelector((state) => state.user);
  const { shopInfo } = useSelector(state => state.shop);
  const [nickName, setNickName] = useState("-");
  const [screen, setScreen] = useState(false);

  const debugMode = debug || ec2Debug || oasispos.debug || payApiDebug || dqrSettingDebug

  useEffect(() => {
    if (user.nickName) setNickName(capitalize(user.nickName));
    setScreen(!screen);
    // setHomePath(user.id ? paths.home : paths.signin);
  }, [user]);

  const styles = { centered: { display: "flex", alignItems: "center" } }

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      style={{ backgroundColor: "white" }}
    >
      <Container>
        <Navbar.Brand>
          <Link to={paths.home}>
            {
              debugMode
                ? <h2 style={{ color: '#05b8a0', margin: 0 }}>DEBUG_MODE 사장님앱</h2>
                : <img src={HeaderLogo} width={144.3} height={31.5} alt="logo" />
            }
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-start">
          <Nav style={{ flex: 1 }}>
            {(isSigned && debug) && <Link to="/pos">ONL-POS  </Link>}
          </Nav>
          <Nav>
            {
              isSigned
                ? (
                  <NavDropdown title={nickName} style={{ width: "200px", textAlign: "right" }}>

                    <NavDropdown.Item as={Link} to={paths.store.orderList} style={styles.centered}>
                      <ListTable />&nbsp;주문 내역 보기
                    </NavDropdown.Item>

                    {
                      (shopInfo.posName !== "OKPOS") &&
                      <NavDropdown.Item as={Link} to={paths.store.orderStatus} style={styles.centered}>
                        <ListTable />&nbsp;주문 현황 및 처리
                      </NavDropdown.Item>
                    }
                    <NavDropdown.Divider />
                    {(
                      shopInfo.posName === "UNIONPOS"
                      || shopInfo.posName === "OASIS_POS"
                    ) &&
                      <NavDropdown.Item as={Link} to={paths.store.paymentSetting} style={styles.centered}>
                        <PayCard />&nbsp;결제 정보 설정
                      </NavDropdown.Item>
                    }

                    <NavDropdown.Item as={Link} to={paths.store.othersSetting} style={styles.centered}>
                      <Setting />&nbsp;기타 설정</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item as={Link} to={paths.store.dqrSetting} style={styles.centered}>
                      <QrCode />&nbsp;DQR 설정</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item as={Link} to={paths.user.setPw} style={styles.centered}>
                      <UserSetting />&nbsp;사용자 PW 변경
                    </NavDropdown.Item>

                    <NavDropdown.Item as={Link} to={paths.user.editUser} style={styles.centered}>
                      <UserSetting />&nbsp;사용자 별칭 변경
                    </NavDropdown.Item>

                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} to="#" onClick={handleLogout} style={styles.centered}>
                      <Signout />&nbsp;로그아웃
                    </NavDropdown.Item>

                    {
                      debug &&
                      <>
                        <NavDropdown.Divider />
                        <NavDropdown.Item as={Link} to="/test/ui-view">테스트 UI View</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/test/excel">테스트 엑셀 업로드</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/post/app-guide">앱 사용 설명서</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item as={Link} to="/test-order">테스트 주문 페이지</NavDropdown.Item>
                      </>
                    }
                  </NavDropdown>
                )
                : (
                  <NavDropdown title="로그인 | 회원등록">
                    <NavDropdown.Item as={Link} to={paths.signin} style={styles.centered}>
                      <Signin />&nbsp;로그인
                    </NavDropdown.Item>
                    <NavDropdown.Item to={paths.signup} as={Link} style={styles.centered}>
                      <UserAdd />&nbsp;가맹점 회원등록
                    </NavDropdown.Item>
                  </NavDropdown>
                )
            }
          </Nav>
        </Navbar.Collapse>
        {/* </div> */}
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
