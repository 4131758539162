import React, { memo, useState, useEffect, useRef } from 'react'
import { useStyles } from '../../../../components'
// import { Text, Price, BestNewHot, BgImage } from './ts/M'
import { Text, Price, BestNewHot, BgImage } from '../components/Miscs'
import Soldout from '../_images/soldout2.png'
import EmptyDish from '../_images/empty-dish.png'
import { getItemName } from '../../../../lib-pos'
import css from './SectionMenu.module.css'
import { debug } from '../../../../settings'

export function GroupBarMobile(props) {
  const { groups, setGroups } = props
  const { colors } = useStyles()

  const myRef = useRef(null)

  const [groupList, setGroupList] = useState([])

  useEffect(() => {
    // console.table(groups)
    setGroupList(groups)
  }, [groups])

  /** @scroll_setting_start *****************************************************/
  let marginTop = 200
  let position = 'static'

  if (props.groupbarMode === 'fixed') {
    marginTop = 0
    position = 'fixed'
  } else {
    marginTop = 200
    position = 'static'
  }

  /** @HeadImage_non_Fiexd 인 경우 */
  marginTop = 0
  /** ***************************************************************************/

  const handleClick2 = (item, idx) => {
    const newList = groups.map((o, i) => {
      return {
        ...o,
        positioned: false,
        selected: i === idx,
        isSelecting: true,
      }
    })
    setGroups(newList)
  }

  const barH = 50
  const borderT = 1

  const styles = {
    wrap: {
      width: '100%',
      display: 'flex',
      overflow: 'auto',
      // backgroundColor: colors.light,
      backgroundColor: '#fff',
      marginTop,
      position,
      zIndex: '11',
      top: 0,
      left: 0,
    },
    groupBox: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: Math.floor(window.innerWidth / 4) - borderT + 'px',
      height: barH,
      borderRight: borderT + 'px solid #fff',
      borderRadius: 3,
    },
  }

  return (
    <div
      className={props.groupbarMode === 'release' ? css.groupbar : ''}
      style={styles.wrap}
      id="groupbar"
      ref={myRef}
    >
      {groupList.map((grp, idx) => {
        return (
          <div
            key={idx}
            style={{
              ...styles.groupBox,
              backgroundColor: (grp.selected || grp.positioned) ? colors.primary : colors.light,
              // backgroundColor:
              //   (grp.isSelecting && grp.selected) ||
              //   (!grp.isSelecting && grp.positioned)
              //     ? colors.primary
              //     : colors.light,
            }}
            onClick={() => handleClick2(grp, idx)}
          >
            <Text
              style={{
                width: '120px',
                textAlign: 'center',
                color: (grp.selected || grp.positioned) ? colors.light : colors.primary,
                // color:
                //   (grp.isSelecting && grp.selected) ||
                //   (!grp.isSelecting && grp.positioned)
                //     ? colors.light
                //     : colors.primary,
              }}
              isBold
              value={grp.displayName}
            />
          </div>
        )
      })}
    </div>
  )
}
