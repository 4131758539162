import React, { useEffect, useState } from "react";

import { FormInput, ModalForm, Button, useStyles } from "../../../../components";
import { getPriceStr } from "../../../../lib/utils";
import { useSelector, useDispatch } from "react-redux";
import { addCartItem } from "../../../../redux/cart";
import { ReactComponent as Smile } from '../../../../assets/icons/Smile.svg'
import { Text, BestNewHot, Price, ChangeQty } from "./compos";
import { ItemOptionInfo } from "./ItemOptionInfo";
import { getItemName } from "../../../../lib-pos";

import { createItemObj2 } from "./createItemObj-testOrder";
import { createCartObj } from "./createCartObj";
import { notifyOrderToStore, goToPayKis } from "../../../../lib/pay-api-lib";
import { sendOrder } from "../../../../lib-pos/sendOrder";
import { saveOrderDataBeforePay } from "../../../../lib-pos/saveOrderDataBeforePay";

import { debug } from "../../../../settings";


export function DetailModal(props) {
  const {
    item, isVisible, setIsVisible,
    shopInfo, itemList, itemOptions2,
  } = props

  // console.log('props', props)
  const dispatch = useDispatch()

  // const isVisible = showDetailPage
  // const setIsVisible = setShowDetailPage
  // const item = selectedItem

  // console.log('item', item)

  const title = `주문 테스트`;

  const [isLoading, setIsLoading] = useState(false)

  const [btnText, setBtnText] = useState("주문")
  const [amount, setAmount] = useState(item.price)
  const [clickEnabled, setClickEnabled] = useState(false)
  const [itemCount2, setItemCount2] = useState(1)
  const [selectedOptions, setSelectedOptions] = useState([])
  // const [tableNo, setTableNo] = useState("6")

  const [comments, setComments] = useState("")

  useEffect(() => {
    if (!item.itemName) return // 초기 값이 {} 일 경우 제외
    if (shopInfo.posName === "UNIONPOS") initSelectedOptionsUnionpos()
    if (shopInfo.posName === "OASIS_POS") initSelectedOptionsOasispos()
    if (shopInfo.posName === "EASY_POS") initSelectedOptionsEasypos()
    if (shopInfo.posName === "DUMMY_POS") initSelectedOptionsDpos()
    if (shopInfo.posName === "OKPOS") alert("Options NOT SUPPORTED")
  }, [props.selectedItem])

  /**
   * @TODO shopInfo에 storeCode=shopNo, masterDataKey, headOfficeNo
   */
  const initSelectedOptionsEasypos = () => {
    try {
      const obj = createItemObj2({ shopInfo, item, itemList, itemOptions2 })
      if (obj.err) throw Error(obj.err.message)

      if (obj.optionClassList) {
        const _selectedOptions = obj.optionClassList.map((optCls) => {
          return {
            ...optCls,
            optionItems: []
          }
        })
        console.log('_selectedOptions', _selectedOptions)
        setSelectedOptions(_selectedOptions)
      }
    } catch (e) {
      alert(e.message)
      setIsVisible(false)
    }
  }

  const initSelectedOptionsUnionpos = () => {
    try {
      const obj = createItemObj2({ shopInfo, item, itemList, itemOptions2 })
      if (obj.err) throw Error(obj.err.message)

      if (obj.optionClassList) {
        const _selectedOptions = obj.optionClassList.map((optCls) => {
          return {
            ...optCls,
            optionItems: []
          }
        })
        console.log('_selectedOptions', _selectedOptions)
        setSelectedOptions(_selectedOptions)
      }
    } catch (e) {
      alert(e.message)
      setIsVisible(false)
    }
  }

  const initSelectedOptionsOasispos = () => {
    try {
      const obj = createItemObj2({ shopInfo, item, itemList, itemOptions2 })
      if (obj.err) throw Error(obj.err.message)

      if (obj.optionClassList) {
        const _selectedOptions = obj.optionClassList.map((optCls) => {
          return {
            ...optCls,
            optionItems: []
          }
        })
        // console.log('_selectedOptions', _selectedOptions)
        // console.table(_selectedOptions)
        setSelectedOptions(_selectedOptions)
      }
    } catch (e) {
      alert(e.message)
      setIsVisible(false)
    }
  }

  const initSelectedOptionsDpos = () => {
    try {
      const obj = createItemObj2({ shopInfo, item, itemList, itemOptions2 })
      if (obj.err) throw Error(obj.err.message)

      if (obj.optionClassList) {
        const _selectedOptions = obj.optionClassList.map((optCls) => {
          return {
            ...optCls,
            optionItems: []
          }
        })
        setSelectedOptions(_selectedOptions)
      }
    } catch (e) {
      alert(e.message)
      setIsVisible(false)
    }
  }

  // 
  // option 메뉴 변경시 invoke
  // 
  useEffect(() => {
    let orderReady = true
    let sum = parseInt(item.price)
    selectedOptions.map((cls) => {
      if (cls.required) orderReady = orderReady && cls.optionItems.length > 0
      cls.optionItems.map((optItem) => {
        sum += optItem.optPrice
      })
    })

    setClickEnabled(orderReady)
    setAmount(sum * itemCount2)
    setBtnText(`주문 (${getPriceStr(sum * itemCount2)} 원)`)

  }, [selectedOptions, itemCount2])

  const onSubmit = async () => {
    try {
      setIsLoading(true)

      // console.log('item', item)
      console.log('selectedOptions', selectedOptions)

      const cartItem = createCartObj({
        shopInfo,
        item,
        itemList,
        itemOptions2,
        selectedOptions,
        itemCount: itemCount2,
      })
      if (cartItem.err) throw Error(cartItem.err.message)

      console.log('cartItem', cartItem)

      dispatch(addCartItem({ item: cartItem }))

      // if (shopInfo.payOptions.isPayable) {
      //   await requestPay([cartItem])
      // } else {
      //   const res = await requestOrderOnly([cartItem])
      //   if (res.err) throw Error(res.err.message)
      //   alert("테스트 주문을 완료하였습니다.");
      // }
    } catch (e) {
      // console.log(e)
      alert(e.message);
    } finally {
      setIsLoading(false);
      setIsVisible(false);
    }
  };

  // const requestOrderOnly = async (cartItems) => {
  //   try {
  //     let res = await sendOrder({
  //       shopInfo: props.shopInfo,
  //       cartItems: props.cartItems,
  //       itemOptions2: props.itemOptions2,
  //       tableNo,
  //       token: "1234",
  //       ver: "v3",
  //     })
  //     if (res.err) throw Error(res.err.message)

  //     const orderId = res.result.saveOrder?.orderId

  //     console.log('orderId', orderId)

  //     if (
  //       props.shopInfo.orderNotifyOptions?.toShop ||
  //       props.shopInfo.posName === "DUMMY_POS"
  //     ) {
  //       const resNoti = await notifyOrderToStore({
  //         shopId: props.shopInfo.shopId,
  //         orderId,
  //         phone_number: '01053174673',
  //       })
  //       if (resNoti.err) throw Error(resNoti.err.message)
  //     }

  //     return {}
  //   } catch (e) {
  //     // console.log(e)
  //     return { err: { message: e.message } }
  //   }

  // }

  // const requestPay = async (cartItems) => {
  //   try {
  //     setIsLoading(true)

  //     let res = await saveOrderDataBeforePay({
  //       shopInfo: props.shopInfo,
  //       cartItems,
  //       itemOptions2,
  //       tableNo,
  //       token: "1234",
  //       ver: "v3",
  //       // notifyToShop: shopInfo.orderNotifyOptions.isPayable
  //     })
  //     if (res.err) throw Error('결제 | 주문 실패 - ', res.err.message)

  //     // return { err: { message: "Test" } }
  //     goToPayKis({ shopId: shopInfo.shopId, orderId: res.result.orderId, pgName: shopInfo.payOptions?.pgName })
  //   } catch (e) {
  //     alert(e.message)
  //     if (debug) console.log(e)
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }


  const styles = {
    wrap: {
      display: 'flex',
      flexDirection: props.isMobilePhone ? "column" : "row",
      justifyContent: 'space-between',
      minHeight: "50vh",
      padding: "0px 5px 15px"
    },
    imgWrap: {
      marginTop: 15,
      width: props.isMobilePhone ? "100%" : "20vw",
      paddingRight: props.isMobilePhone ? 0 : 15,
      display: 'flex', flexDirection: "column",
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    infoWrap: {
      width: props.isMobilePhone ? "100%" : '30vw',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    sum: {
      marginTop: 15,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }

  return (
    <ModalForm
      visible={isVisible}
      setIsVisible={setIsVisible}
      title={title}
      size="lg"
    >
      <div style={styles.wrap}>
        {/* <div style={styles.imgWrap}>
          {item.image ? (
            <img
              src={item.image}
              alt="item menu picture"
              style={{ borderRadius: '15px', width: '90%' }}
            />
          ) : (
            <Smile />
          )}

          <FormInput
            label="Table No"
            value={tableNo}
            onChange={(e) => setTableNo(e.target.value)}
          />
        </div> */}

        <div style={styles.infoWrap}>
          <ItemInfo {...props} item={item} />
          <ItemOptionInfo
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
          <div style={styles.sum}>
            <Price
              price={amount}
              style={{ width: '50%' }}
              size="lg"
            />
            <ChangeQty itemCount={itemCount2} setItemCount={setItemCount2} size="lg" />
          </div>
        </div>
      </div>

      {/* {comments !== "" &&
        <p style={{ textAlign: "center" }}>{comments}</p>
      } */}

      <Button
        size="sm"
        variant="primary"
        width="100%"
        title={btnText}
        onClick={onSubmit}
        isLoading={isLoading}
        disabled={!clickEnabled}
      />
    </ModalForm>
  )
}

function ItemInfo(props) {
  const { item } = props

  const [itemName, setItemName] = useState("")

  useEffect(() => {
    const _itemName = getItemName({
      ...props,
      item,
      selectedLanguage: "KR",
      usePosMenuFirst: false,
    })
    console.log(_itemName)
    setItemName(_itemName)
  }, [item])

  const styles = {
    wrap: {
      // marginTop: 15,
      // marginBottom: 15,
    },
    title: { margin: '0px 0px 5px' },
    desc: { marginBottom: 5, color: '#666' },
  }

  return (
    <div style={styles.wrap}>
      <BestNewHot item={item} />
      <Text value={itemName} isTitle />
      <Price price={item.price} />
      <Text style={styles.desc} value={item.description} />
    </div>
  )
}
