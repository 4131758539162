import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  AppliedRoute,
  AuthenticatedRoute,
} from "./conditionalRoutes";

import { routePaths } from "./routePaths";

import NotFound from "../pages/NotFound";
import UIview from "../pages/test/UI-Test";
import ExcelTest from "../pages/test/Excel-test";

// import Home from "../pages/home";
import Login2 from "../pages/Login2";
import Signup2 from "../pages/signup/Signup2";
import SetPassword from "../pages/login/SetPassword2";
import FindUsername from "../pages/login/FindUsername";
import EditNickname from "../pages/login/User-nickname";

// 
// shop pages
// 
import StoreHome from "../pages/shopPage2/StoreHome";
import StoreDetail from "../pages/shopPage2/StoreDetail";
import StoreRegisterPosname from "../pages/shopPage2/StoreRegisterPosname";
import StoreRegister from "../pages/shopPage2/StoreRegister";
import OptionMenus from "../pages/shopPage2/OnlOptions";

import OrderHistory from "../pages/shopPage2/OrderHistrory";
import OrderStatus from "../pages/shopPage2/OrderStatus";

import PaymentSetting from "../pages/shopPage2/Settings/Payment";
import DqrSetting from "../pages/shopPage2/Settings/DQR";
import OthersSetting from "../pages/shopPage2/Settings/Others";

import PostView from "../pages/post/PostView";

import TestOrder from "../pages/shopPage2/TestOrder"

// POS HOME
import PosHome from "../pages/Pos/pages/Index-Pos";
import SalesIndex from "../pages/Pos/pages/sale/Index-Sale";
import SettingIndex from "../pages/Pos/pages/setting/Index-Setting";
import MenuSetting from "../pages/Pos/pages/setting/MenuSetting";
import PosSetting from "../pages/Pos/pages/setting/PosSetting";

import { debug } from "../settings";

function Routes(props) {
  return (
    <Switch>
      <AppliedRoute path={routePaths.home} exact component={StoreHome} />
      <AppliedRoute path={routePaths.signin} exact component={Login2} />
      <AppliedRoute path={routePaths.setPw} exact component={SetPassword} />
      <AppliedRoute path={routePaths.findId} exact component={FindUsername} />
      <AppliedRoute path={routePaths.signup} exact component={Signup2} />

      <AuthenticatedRoute path={routePaths.user.setPw} exact component={SetPassword} />
      <AuthenticatedRoute path={routePaths.user.editUser} exact component={EditNickname} />

      <AuthenticatedRoute path={routePaths.store.settingOptionMenus} exact component={OptionMenus} />
      <AuthenticatedRoute path={routePaths.store.detail} exact component={StoreDetail} />
      {/* <AuthenticatedRoute path="/store/pos-simulation/okpos" exact component={OKPosTest} /> */}
      <AuthenticatedRoute path={routePaths.store.paymentSetting} exact component={PaymentSetting} />
      <AuthenticatedRoute path={routePaths.store.othersSetting} exact component={OthersSetting} />
      <AuthenticatedRoute path={routePaths.store.dqrSetting} exact component={DqrSetting} />

      <AuthenticatedRoute path={routePaths.store.orderList} exact component={OrderHistory} />
      <AuthenticatedRoute path={routePaths.store.orderStatus} exact component={OrderStatus} />

      {/* 사용하는 POS를 먼저 등록함. */}
      <AuthenticatedRoute path="/store/register-posname" exact component={StoreRegisterPosname} />
      {/* action: create | edit */}
      <AuthenticatedRoute path="/store/register/:action" exact component={StoreRegister} />

      {debug && <AuthenticatedRoute path="/test/ui-view" exact component={UIview} />}
      {debug && <AuthenticatedRoute path="/test/excel" exact component={ExcelTest} />}

      <AuthenticatedRoute path="/post/view" exact component={PostView} />
      <AuthenticatedRoute path="/post/app-guide" exact component={PostView} />

      {/* Test Order page */}
      {debug && <AuthenticatedRoute path="/test-order/" exact component={TestOrder} />}

      {/* POS */}
      {debug &&
        <>
          <AuthenticatedRoute path="/pos" exact component={PosHome} />
          <AuthenticatedRoute path="/pos/sale" exact component={SalesIndex} />

          <AuthenticatedRoute path="/pos/setting" exact component={SettingIndex} />
          <AuthenticatedRoute path="/pos/setting/menus" exact component={MenuSetting} />
          <AuthenticatedRoute path="/pos/setting/pos-system" exact component={PosSetting} />
        </>
      }
      {/* {debug && <AuthenticatedRoute path="/pos/" exact component={PosHome} />} */}

      <Route component={NotFound} />
    </Switch>
  );
}

export default Routes;
