import React, { memo, useState, useEffect, useRef } from 'react'
import { useStyles } from '../../../../components'
// import { Text, Price, BestNewHot, BgImage } from './ts/M'
import {
  Text,
  Price,
  // BestNewHot,
  // BgImage
} from '../components/Miscs'
// import Soldout from '../_images/soldout2.png'
import EmptyDish from '../_images/empty-dish.png'
import { getItemName } from '../../../../lib-pos'
// import css from './SectionMenu.module.css'
import { debug } from '../../../../settings'

export function MenuItemsMobile(props) {
  const { groups } = props
  const { gStyle } = useStyles()
  const [mTop, setMTop] = useState(0)

  useEffect(() => {
    // console.log(props.groupbarMode)
    if (props.groupbarMode === 'fixed') {
      setMTop(50)
    } else {
      setMTop(0)
    }
  }, [props.groupbarMode])

  const styles = {
    wrap: {
      // position: 'relative', // header fixed position 일 경우에 overlay 가능하도록 함함
      overflow: 'auto',
      width: '100%',
      backgroundColor: '#fff',
      zIndex: '10',

      /** @scroll_up_groupbarTop */
      marginTop: mTop,
      // paddingTop: marginTop,
    },
    groupText: {
      ...gStyle.subtitle,
      ...gStyle.highLight,
      padding: '15px 15px 15px 5px',
      borderBottom: '7px solid #efefef',
    },
  }

  return (
    <div style={styles.wrap} id="menulist">
      {groups.map((g, i) => {
        return <ItemsByGroup key={i} idx={i} group={g} {...props} />
      })}
    </div>
  )
}

function ItemsByGroup(props) {
  const { group, idx } = props
  const { gStyle, colors } = useStyles()
  const myRef = useRef()

  useEffect(() => {
    if (!(props.groupbarMode === 'default' && idx === 0)) {
      if (group.selected) {
        // selectScrollEnd()
        scrollMove(idx)
      }
    }
  }, [group])

  const scrollMove = (idx) => {
    if (myRef.current) {
      myRef.current.scrollIntoView({
        behavior: 'smooth',
        // block: 'center', // start(default) | center | end | nearest
      })
    }
  }

  /** @scroll_and_그룹버튼_변경은_scroll중복으로_어려움이_있어_일단_보류함  */
  /** @TODO IntersectionObserver 사용 검토 - ChatGpt 제안 */
  // const [isSelectScrolling, setIsSelectScrolling] = useState(false)
  // const scrollRef = useRef(isSelectScrolling)

  // useEffect(()=>{
  //   console.log("isSelectScrolling", isSelectScrolling)
  //   scrollRef.current = isSelectScrolling
  // }, [isSelectScrolling])

  // const selectScrollEnd = () => {
  //   setIsSelectScrolling(true)
  //   let scrollTimeout;
  //   clearTimeout(scrollTimeout)
  //   // Set a timeout to trigger after scrolling stops
  //   scrollTimeout = setTimeout(() => {
  //     setIsSelectScrolling(false)
  //     // window.removeEventListener('scroll', onScroll)
  //   }, 500) // Adjust delay as needed
  // }

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, { capture: true })
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  // }, [])

  // const handleScroll = () => {
  //   if (myRef.current) {
  //     const elTop = myRef.current?.getBoundingClientRect()?.top
  //     if (elTop > 10 && elTop < 100) {
  //       const newGroups = props.groups.map((o, i) => {
  //         return {
  //           ...o,
  //           selected: false,
  //           positioned: i === idx,
  //         }
  //       })

  //       if (!props.groups[idx].positioned) {
  //         props.setGroups(newGroups)
  //       }
  //     }
  //   }
  // }

  const styles = {
    groupText: {
      ...gStyle.subtitle,
      ...gStyle.highLight,
      padding: '15px 15px 15px 5px',
      borderBottom: '7px solid #efefef',
    },
  }

  return (
    <div id={group.groupCode} name={group.displayName} ref={myRef}>
      <p style={styles.groupText}>{group.displayName}</p>
      {group.itemList.length > 0 &&
        group.itemList.map((item, i) => {
          return <ItemMobile2 key={i} {...props} item={item} />
        })}
    </div>
  )
}

function ItemMobile2(props) {
  const {
    item,
    lang,
    shopInfo,
    itemOptions,
    setAlert,
    setSelectedItem,
    setShowDetailPage,
  } = props
  const { colors } = useStyles()

  const borderRadius = 5
  const borderT = 1
  const outerMargin = 3
  const innerMargin = 3

  const maxItemBoxW = 80
  const maxImgSize =
    maxItemBoxW - outerMargin * 2 - innerMargin * 2 - borderT * 2
  const imgSize = Math.floor(maxImgSize * 0.95)
  const [itemName, setItemName] = useState(item.itemName)

  useEffect(() => {
    function start() {
      // console.log('='.repeat(50), item.itemName)
      // console.log('shopInfo', props.shopInfo)
      // console.log('itemOptions', props.itemOptions)
      // console.log('description', item.description)

      try {
        let usePosMenuFirst = itemOptions.menuSettings.usePosMenuFirst

        const _itemName = getItemName({
          shopInfo,
          item,
          usePosMenuFirst,
          selectedLanguage: 'KR',
        })

        // console.log('itemName', _itemName)
        setItemName(_itemName)

        // let res = getSetmenuText({
        //   shopInfo,
        //   item,
        //   itemList,
        //   itemOptions2,
        //   usePosMenuFirst,
        // })
        // if (res.err) throw Error(res.err.message)
        // setSetmenuText(res.result)

        // setImgUrl(getTnUrl(item.image))

        // let serviceTime = {}
        // if (isJson(item.serviceTime)) serviceTime = JSON.parse(item.serviceTime)
        // setItemSTime(serviceTimeSet(serviceTime))
      } catch (e) {
        alert(e.message)
        if (debug) console.log(e)
      }
    }
    start()
  }, [item, lang])

  const onItemClick = () => {
    if (item.isSoldout) {
      setAlert('Soldout 메뉴입니다.')
      return
    }
    setSelectedItem(item)
    setShowDetailPage(true)
  }

  const styles = {
    wrap: {
      // margin: outerMargin,
      padding: outerMargin,
      // border: borderT + 'px solid #aaa',
      // borderRadius,
      borderBottom: '7px solid #efefef',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // backgroundColor: "#fff",
      // zIndex: "99"
    },
    imgWrap: {
      margin: 5,
      width: imgSize,
      height: imgSize,
      // zIndex: "99"
    },
    image: {
      borderRadius,
      opacity: item.isSoldout ? 0.3 : 1,
      width: imgSize,
      height: imgSize,
      // zIndex: "99"
    },
    textWrap: {
      margin: 5,
      flex: 1,
      // zIndex: "99"
    },
    text: {
      color: item.isSoldout ? '#dfdfdf' : colors.dark,
      // zIndex: "99"
    },
  }

  return (
    <div style={styles.wrap} onClick={onItemClick}>
      <div style={styles.textWrap}>
        <Text value={itemName} isBold style={styles.text} />
        <Price price={item.price} lang={lang} style={styles.text} />
        {!item.isSoldout && (
          <Text value={item.description} style={styles.text} />
        )}
        {item.isSoldout && (
          <Text
            value="품절 상품입니다."
            isBold
            style={{ color: colors.danger }}
          />
        )}
      </div>

      <div style={styles.imgWrap}>
        <img style={styles.image} src={item.image || EmptyDish} />
      </div>
    </div>
  )
}
