import React, { memo, useState, useEffect } from 'react'
import { createValidMenuList } from '../_utils/createValidMenuList'
// import { MenuItemsMobile } from './MenusMobile'
import { GroupBarMobile } from './GroupBarMobile'
import { MenuItemsMobile } from './MenuItemsMobile'

import { GroupBarTablet } from './GroupBarTablet'
import { MenuItemsTablet } from './MenuItemsTablet'

function MenuInfo_(props) {
  const { shopInfo, itemOptions2, itemList, setAlert, isMobilePhone } = props

  const [groups, setGroups] = useState([])

  useEffect(() => {
    function start() {
      try {
        const res = createValidMenuList({ shopInfo, itemList, itemOptions2 })
        setGroups(res.menuList)
      } catch (e) {
        setAlert(e.message)
      }
    }
    start()
  }, [])

  if (groups.length > 0) {
    return (
      <>
        {isMobilePhone && (
          <>
            <GroupBarMobile {...props} groups={groups} setGroups={setGroups} />
            <MenuItemsMobile {...props} groups={groups} setGroups={setGroups} />
          </>
        )}

        {!isMobilePhone && (
          <>
            <GroupBarTablet {...props} groups={groups} setGroups={setGroups} />
            <MenuItemsTablet {...props} groups={groups} setGroups={setGroups} />
          </>
        )}
      </>
    )
  } else return null
}

const MenuSection = memo(MenuInfo_)
export default MenuSection
