import React, { useState, useEffect } from 'react'
import { Loader } from '../../../components'
import { Alert } from '../../../components'
import { debug } from '../../../settings'
import HeadSection from './SectionHead'
import MenuSection from './SectionMenu'
import { DetailModal } from '../components/TestOrder/DetailModal'
import CartButton from './ModalCart/CartBtn'
import CartModal from './ModalCart'

export default function MenuHome(props) {
  const tableNo = '003'

  // const dispatch = useDispatch()
  // const { shopInfo } = useSelector((state) => state.shop)
  // // const { itemList3, itemOptions3 } = useSelector((state) => state.items)

  // /** @TODO iPAD는 isMobileDevice isTablet 모두 false임 추가 체크 필요 */
  // const { isMobileDevice, isTablet, isMobilePhone } = useSelector(
  //   (state) => state.platform,
  // )

  //
  // cart
  //
  const [showCartPage, setShowCartPage] = useState(false)

  // //
  // // detail
  // //
  const [showDetailPage, setShowDetailPage] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})

  // const [lang, setLang] = useState({})
  const [token, setToken] = useState('1234')
  const [isLoading, setLoading] = useState(false)
  const [ready, setReady] = useState(true)

  // // kebin addes
  // const [shopInfo2, setShopInfo2] = useState({})
  // const [itemList, setItemList] = useState([])
  // const [itemOptions2, setItemOptions2] = useState({})
  const [alertMsg, setAlert] = useState('')

  useEffect(() => {
    const sysLang = navigator.language.split('-')[0].toUpperCase()

    //
    // lang : 언어별 텍스를 포함
    //
    // setLang(setLanguage(sysLang))
  }, [])

  useEffect(() => {
    async function start() {
      try {
        // const parsed = queryString.parse(window.location.search)
        // if (debug) console.log(parsed)
        // setToken(parsed.token)
        // let res = await getShopInfo({ shopId })
        // if (res.err) throw Error(res.err.message)
        // const _shopInfo = res.result.Item
        // const parsedShop = parseShopInfo({ shopInfo: _shopInfo })
        // if (debug) console.log('shopInfo', parsedShop)
        // setShopInfo2(parsedShop)
        // dispatch(setShopInfo({ shopInfo: parsedShop }))
        // res = await getItemList({ shopInfo: _shopInfo, token: parsed.token })
        // if (res.err) throw Error(res.err.message)
        // setItemList(res.result.Items)
        // dispatch(setItemList3({ itemList3: res.result.Items }))
        // res = await getItemOptions({ shopInfo: _shopInfo })
        // if (res.err) throw Error(res.err.message)
        // setItemOptions2(res)
        // dispatch(setItemOptions3({ itemOptions3: res }))
        // setLoading(false)
        // setReady(true)
      } catch (e) {
        setAlert(e.message)
        if (debug) console.log(e)
      }
    }
    start()
  }, [])

  /** ******************************************************************
   * @scroll_handler_start
   ********************************************************************/

  //
  //        groupbar_mode   groupbar_position groupbar_marginTop    menu_marginTop    enter when
  // case 1 default         static            200                   0                 initial
  // case 2 fixed           fixed             0                     50                defaultMode && menuY <= 50
  // case 3 release         static            menuY-50              0                 fixedMode && menuY === 50
  //              release mode 는 animation 때문에 필요함
  // back to default                                                                  release &&  menuY <= 250
  //
  // groupbar_marginTop value 200
  // groupbar height value     50
  //

  const [menuScrollY, setMenuScrollY] = useState(250)
  const [groupbarMode, setGroupbarMode] = useState('default')

  const [isScrolling, setIsScrolling] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { capture: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const menulist = document.getElementById('menulist')
    const menulistTop = menulist?.getBoundingClientRect()?.top
    setMenuScrollY((pre) => {
      return menulistTop
    })
  }

  useEffect(() => {
    if (groupbarMode === 'default') {
      if (menuScrollY <= 50) setGroupbarMode('fixed')
    }

    if (groupbarMode === 'fixed') {
      if (menuScrollY >= 50) setGroupbarMode('release')
    }

    if (groupbarMode === 'release') {
      if (menuScrollY <= 50) setGroupbarMode('fixed')
      if (menuScrollY <= 250) setGroupbarMode('default')
    }
  }, [menuScrollY])

  /** ******************************************************************
   * @scroll_handler_end
   ********************************************************************/

  const allProps = {
    ...props,

    // // options
    // ver,
    // isTablet,
    // isMobileDevice,
    // isMobilePhone,

    // operation commons
    isLoading,
    setLoading,
    token,
    setAlert,
    item: selectedItem,
    selectedItem,
    setSelectedItem,
    showDetailPage,
    setShowDetailPage,
    setShowCartPage,

    // cartItems,

    // scroll check
    menuScrollY,
    groupbarMode,
    setGroupbarMode,

    isScrolling,
    setIsScrolling,
  }

  return (
    <>
      {ready && (
        <>
          <HeadSection {...allProps} />
          <MenuSection {...allProps} />
          <CartButton {...allProps} />

          {showDetailPage && (
            <DetailModal
              {...allProps}
              item={selectedItem}
              isVisible={showDetailPage}
              setIsVisible={setShowDetailPage}
            />
          )}

          {showCartPage && (
            <CartModal
              {...allProps}
              isVisible={showCartPage}
              setIsVisible={setShowCartPage}
            />
          )}

          <Alert
            title={alertMsg}
            visible={alertMsg !== ''}
            onClickOk={() => setAlert('')}
            onClickCancel={() => setAlert('')}
          />
        </>
      )}
      {isLoading && <Loader />}
    </>
  )
}
